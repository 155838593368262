import React from "react";
import { useMatches } from "react-router-dom";

import { usePermissionChecker } from "~/base/user-data";

function useIsRouteAllowed() {
  const matches = useMatches();
  const hasPermission = usePermissionChecker();
  const isAllowed = React.useMemo(
    () =>
      matches.every(
        (m) =>
          !(m.handle && m.handle.requiredPermissions) ||
          hasPermission(m.handle.requiredPermissions)
      ),
    [matches, hasPermission]
  );
  return isAllowed;
}

export { useIsRouteAllowed };
