import { faRotate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
  Button,
  ButtonGroup,
  Col,
  Dropdown,
  Modal,
  Row,
} from "react-bootstrap";

import Form from "~/components/form";
import { isValidationError } from "~/components/form/error";
import SubmitButton from "~/components/form/submit";
import { useAlert, axios } from "~/utils";

function SynchroniseButton({ onSync }) {
  const [loading, setLoading] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const addAlert = useAlert();

  const onClick = () => {
    setLoading(true);
    if (!loading) {
      onSync(axios({ url: "api/sync-ad-units", method: "POST" }))
        .then(({ data }) => {
          data.messages.forEach(([variant, message]) => {
            addAlert(message, variant);
          });
        })
        .catch(() => {
          addAlert("Error while syncing", "error");
        })
        .finally(() => setLoading(false));
    }
  };
  const icon = loading ? (
    <div className="spinner-border spinner-border-sm" />
  ) : (
    <FontAwesomeIcon icon={faRotate} />
  );

  return (
    <>
      <Dropdown as={ButtonGroup}>
        <Button onClick={onClick}>
          <span className="me-2">{icon}</span> Synchronise
        </Button>
        <Dropdown.Toggle variant="outline-primary" split />
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => setShowModal(true)}>
            Add ad unit from MAX
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add ad unit from MAX</Modal.Title>
        </Modal.Header>
        <Form
          form={false}
          initialValues={{ maxId: "" }}
          onSubmit={(values) =>
            onSync(
              axios({
                url: "api/pull-ad-unit",
                method: "POST",
                data: values,
              })
            )
              .then(({ data }) => {
                addAlert(data.detail, "success");
                setShowModal(false);
              })
              .catch((error) => {
                if (isValidationError(error)) throw error;
                addAlert(
                  `Error while adding ad unit from MAX: ${error.response.data.detail}`,
                  "error"
                );
              })
          }
        >
          <Modal.Body className="px-5">
            <Form.InnerForm>
              <Form.Group as={Row} className="mb-3" controlId="max-id">
                <Form.Label column sm={4}>
                  Max ID
                </Form.Label>
                <Col sm={8}>
                  <Form.Control name="maxId" />
                  <Form.Control.ErrorFeedback name="maxId" />
                </Col>
              </Form.Group>
              {/* this input is needed to allow to submit form by pressing enter */}
              <input type="submit" className="d-none" />
            </Form.InnerForm>
          </Modal.Body>
          <Modal.Footer>
            <SubmitButton title="Pull" />
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default SynchroniseButton;
