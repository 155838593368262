import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  camelJoin,
  camelRemovePrefix,
  camelToKebab,
  kebabToCamel,
} from "~/utils/case";
import { useVarRef } from "~/utils/hooks";

function useSearchParams(namespace = null) {
  const wrapped = React.useContext(SearchParamsContext);
  // if you change namespace between renders, it's your headache then
  if (!namespace) {
    return wrapped;
  }
  const state = getSubState(wrapped.state, namespace);
  const search = objectToSearch(state);
  /* eslint-disable react-hooks/rules-of-hooks, react-hooks/exhaustive-deps */
  const setState = React.useCallback(
    (value) => {
      let newValue;
      if (typeof value === "function") {
        newValue = (currentState) => ({
          ...removeSubState(currentState, namespace),
          ...applySubState(
            value(getSubState(currentState, namespace)),
            namespace
          ),
        });
      } else {
        newValue = applySubState(value, namespace);
      }
      wrapped.setState(newValue);
    },
    [wrapped.setState, namespace]
  );
  /* eslint-enable react-hooks/rules-of-hooks, react-hooks/exhaustive-deps */
  return {
    search,
    state,
    setState,
  };
}

function SearchParamsProvider({ children }) {
  const value = useSearchParamsImplementation();
  return (
    <SearchParamsContext.Provider value={value}>
      {children}
    </SearchParamsContext.Provider>
  );
}

function useSearchParamsImplementation() {
  const location = useLocation();
  const navigate = useNavigate();
  const state = React.useMemo(
    () => searchToObject(location.search),
    [location.search]
  );
  const stateRef = useVarRef(state);

  const setState = React.useCallback(
    (payload) => {
      let newState = stateRef.current;
      if (typeof payload === "function") {
        newState = payload(newState);
      } else {
        newState = { ...newState, ...payload };
      }
      newState = dropEmptyValues(newState);
      navigate({ search: objectToSearch(newState) || "?" });
    },
    [navigate, stateRef]
  );

  return {
    setState,
    state,
    search: location.search,
  };
}

function searchToObject(search) {
  const result = {};
  Array.from(new URLSearchParams(search).entries()).forEach(
    ([urlKey, value]) => {
      const key = kebabToCamel(urlKey);
      result[key] = stringToArray(value);
    }
  );
  return result;
}

function objectToSearch(state) {
  const searchParams = new URLSearchParams();
  Object.entries(state).forEach(([key, value]) => {
    const urlKey = camelToKebab(key);
    searchParams.append(urlKey, arrayToString(value));
  });
  const result = searchParams.toString();
  return result.length > 0 ? `?${result}` : "";
}

function stringToArray(value) {
  return value.includes("_")
    ? value.split("_").map((v) => v.replaceAll("%5F", "_"))
    : value.replaceAll("%5F", "_");
}

function arrayToString(value) {
  return Array.isArray(value)
    ? value.map((v) => String(v).replaceAll("_", "%5F")).join("_")
    : String(value).replaceAll("_", "%5F");
}

const SearchParamsContext = React.createContext();

function dropEmptyValues(obj) {
  const entries = Object.entries(obj)
    .filter(([, v]) => !(Array.isArray(v) && v.length === 0))
    .filter(([, v]) => !(v == null));
  return Object.fromEntries(entries);
}

function getSubState(state, namespace) {
  let entries = Object.entries(state);
  entries = entries
    .filter(([k]) => k.startsWith(namespace))
    .map(([k, v]) => [camelRemovePrefix(k, namespace), v]);
  return Object.fromEntries(entries);
}

function removeSubState(state, namespace) {
  let entries = Object.entries(state);
  entries = entries.filter(([k]) => !k.startsWith(namespace));
  return Object.fromEntries(entries);
}

function applySubState(subState, prefix) {
  let entries = Object.entries(subState);
  entries = entries.map(([k, v]) => [camelJoin(prefix, k), v]);
  return Object.fromEntries(entries);
}

export { SearchParamsProvider, useSearchParams };
