import classnames from "classnames";
import React from "react";
import { Link, useMatches } from "react-router-dom";

import NavigationIcon from "./icon";
import NavigationItem from "./item";
import sectionContext from "./section-context";

function NavigationLink({ className, to, icon, title, minimized = false }) {
  const isInSection = React.useContext(sectionContext);
  const innerMinimised = minimized && !isInSection;
  const matches = useMatches();
  const isActive = matches.some((e) => e.pathname === to);
  return (
    <NavigationItem minimized={innerMinimised}>
      <Link
        className={classnames("nav__link nav-link", className, {
          minimized: innerMinimised,
          active: isActive,
        })}
        to={to}
        title={title}
      >
        <NavigationIcon icon={icon} minimized={innerMinimised} />
        {!innerMinimised && <p className="title with-spacing mb-0">{title}</p>}
      </Link>
    </NavigationItem>
  );
}

export default NavigationLink;
