/* eslint-disable jsx-a11y/anchor-is-valid */
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import React from "react";
import { Collapse } from "react-bootstrap";
import { useMatch } from "react-router-dom";

import { useOnClickOutside, useVarRef } from "~/utils";

import NavigationIcon from "./icon";
import NavigationItem from "./item";
import sectionContext from "./section-context";

function NavigationSection({
  icon,
  title,
  prefix,
  autoCollapse = false,
  children,
  minimized = false,
}) {
  const match = useMatch({ path: prefix, end: false });
  const hasActiveChild = prefix ? !!match : false;

  const [opened, setOpened] = React.useState(!minimized && hasActiveChild);
  const wrapperRef = React.useRef(null);
  const openSideMenuRef = React.useRef(null);
  const sectionRef = React.useRef(null);
  const shouldCloseRef = useVarRef(autoCollapse || minimized);
  const onClickOutside = React.useCallback(() => {
    if (shouldCloseRef.current) {
      setOpened(false);
    }
  }, [shouldCloseRef, setOpened]);
  useOnClickOutside(wrapperRef, onClickOutside);

  const showPopup = () => {
    if (!minimized) return;
    openSideMenuRef.current.classList.add("opened");

    const openSideMenuRect = openSideMenuRef.current.getBoundingClientRect();
    const sectionRefRect = sectionRef.current.getBoundingClientRect();

    if (
      sectionRefRect.y + sectionRefRect.height + openSideMenuRect.height >=
      window.innerHeight
    ) {
      const top =
        sectionRefRect.y - openSideMenuRect.height + sectionRefRect.height * 2;

      openSideMenuRef.current.style.top = `${top}px`;
    } else {
      openSideMenuRef.current.style.top = `${
        sectionRefRect.y + sectionRefRect.height
      }px`;
    }

    setOpened(true);
  };

  const hidePopup = (e) => {
    if (!minimized) return;

    e.persist();

    if (!e.target.classList.contains("side-menu-wrapper")) {
      openSideMenuRef.current.classList.remove("opened");
      setOpened(false);
    }
  };

  return (
    <div
      className="nav__section-root"
      ref={wrapperRef}
      onFocus={showPopup}
      onMouseOver={showPopup}
      onMouseLeave={hidePopup}
    >
      <sectionContext.Provider value>
        <NavigationItem minimized={minimized} title={title}>
          <a
            ref={sectionRef}
            className={classnames("nav__section-header nav-link", {
              minimized,
              active: hasActiveChild,
            })}
            onClick={() => !minimized && setOpened(!opened)}
          >
            <NavigationIcon icon={icon} minimized={minimized} />
            {!minimized && (
              <>
                <span className="title with-spacing">{title}</span>
                <div
                  className={classnames("nav__section-icon text-dark", {
                    opened,
                  })}
                >
                  {" "}
                  <FontAwesomeIcon icon={faAngleLeft} />
                </div>
              </>
            )}
          </a>
        </NavigationItem>

        {minimized ? (
          <div
            ref={openSideMenuRef}
            onMouseEnter={() => setOpened(true)}
            onMouseLeave={() => {
              openSideMenuRef.current.classList.remove("opened");
              setOpened(false);
            }}
            className="nav__section-popup side-menu-wrapper"
          >
            <div className="nav__section-popup-content">{children}</div>
          </div>
        ) : (
          <Collapse in={opened}>
            <div className="nav__section-content">{children}</div>
          </Collapse>
        )}
      </sectionContext.Provider>
    </div>
  );
}

export default NavigationSection;
