import React from "react";
import { useMatches } from "react-router-dom";

function TitleChanger() {
  const matches = useMatches();
  const lastMatch = matches.filter((e) => e.handle && e.handle.title).at(-1);
  let title = lastMatch && lastMatch.handle.title;
  if (typeof title === "function") {
    title = title(lastMatch.data);
  }
  React.useEffect(() => {
    if (title) {
      document.title = title;
    }
  }, [title]);
}

export default TitleChanger;
