import React from "react";
import { Row, Col } from "react-bootstrap";

import { ResetButton, FilterCol } from "~/components/filters";
import Form from "~/components/form";
import SubmitButton from "~/components/form/submit";
import { useFilters } from "~/components/page-header";
import { cachedAxios, useSearchParams } from "~/utils";

const activeOptions = [
  { value: "all", label: "All" },
  { value: "active", label: "Only active" },
];

async function filtersLoader() {
  const response = await cachedAxios("api/ad-units-filters");
  return response.data;
}

function PageFiltersForm({ options }) {
  const { state, setState } = useSearchParams();
  const { setFilterOpened } = useFilters([
    "name",
    "maxId",
    "active",
    "adFormat",
    "app",
    "platform",
  ]);

  const initialValues = {
    name: state.name || "",
    maxId: state.maxId || "",
    active: state.active === "0" ? "all" : "active",
    format: state.adFormat || "",
    app: state.app || "",
    platform: state.platform || "",
  };

  const onChange = (values) => {
    setState({
      name: values.name || null,
      maxId: values.maxId || null,
      active: values.active === "active" ? null : "0",
      adFormat: values.format || null,
      app: values.app || null,
      platform: values.platform || null,
      page: 1,
    });
    setFilterOpened(false);
  };
  return (
    <FiltersForm
      options={options}
      initialValues={initialValues}
      onChange={onChange}
    />
  );
}

function FiltersForm({ options, initialValues, onChange }) {
  return (
    <Form initialValues={initialValues} syncSubmit onSubmit={onChange}>
      <Row className="mb-3 justify-content-md-center">
        <FilterCol label="Name">
          <Form.Control name="name" />
        </FilterCol>
        <FilterCol label="Max ID">
          <Form.Control name="maxId" />
        </FilterCol>
        <FilterCol label="App">
          <Form.Select name="app" options={options.apps} />
        </FilterCol>
        <FilterCol label="Platform">
          <Form.Select name="platform" options={options.platforms} />
        </FilterCol>
        <FilterCol label="Ad format">
          <Form.Select name="format" options={options.formats} />
        </FilterCol>
        <FilterCol label="Active">
          <Form.CheckList
            inline
            name="active"
            type="radio"
            options={activeOptions}
          />
        </FilterCol>
      </Row>
      <Row>
        <Col className="d-flex flex-row justify-content-end" md={12} sm={10}>
          <Col xs="auto" className="me-2">
            <ResetButton />
          </Col>
          <Col xs="auto">
            <SubmitButton icon={false} title="Apply" />
          </Col>
        </Col>
      </Row>
    </Form>
  );
}

export { filtersLoader, PageFiltersForm, FiltersForm };
