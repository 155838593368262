import React from "react";
import { Table } from "react-bootstrap";
import { Link, useLoaderData, useRevalidator, defer } from "react-router-dom";

import { filtersLoader, PageFiltersForm } from "~/blocks/ad-units/filters";
import ActiveIndicator from "~/components/active-indicator";
import IconButton from "~/components/icon-button";
import PageHeader from "~/components/page-header";
import Pagination from "~/components/pagination";
import SimpleAwaiter from "~/components/simple-awaiter";
import { axios, cachedAxios, extractSearch } from "~/utils";

import DuplicateButton from "./duplicate-button";
import SynchroniseButton from "./synchronise-button";

function AdUnitsPage() {
  const { adUnits, filtersPromise, optionsPromise } = useLoaderData();
  const revalidator = useRevalidator();
  const [syncStatus, setSyncStatus] = React.useState("initial");
  /* eslint-disable react-hooks/rules-of-hooks, react-hooks/exhaustive-deps */
  React.useEffect(() => {
    if (syncStatus === "success") {
      revalidator.revalidate();
    }
  }, [syncStatus]);
  /* eslint-enable react-hooks/rules-of-hooks, react-hooks/exhaustive-deps */

  const handleSync = (promise) => {
    setSyncStatus("syncing");
    return promise
      .then((value) => {
        setSyncStatus("success");
        return value;
      })
      .catch((error) => {
        setSyncStatus("fail");
        throw error;
      });
  };

  return (
    <>
      <PageHeader
        filter={
          <SimpleAwaiter promise={filtersPromise}>
            {(filters) => <PageFiltersForm options={filters} />}
          </SimpleAwaiter>
        }
      >
        <SynchroniseButton onSync={handleSync} />
      </PageHeader>
      <AdUnitsTable data={adUnits} optionsPromise={optionsPromise} />
    </>
  );
}

AdUnitsPage.loader = async function loader({ request }) {
  const search = extractSearch(request.url);
  const response = await axios(`api/ad-units${search}`);
  const filtersPromise = filtersLoader();
  const optionsPromise = cachedAxios("api/ad-units-choices").then(
    (r) => r.data
  );

  return defer({
    adUnits: response.data,
    filtersPromise,
    optionsPromise,
  });
};

function AdUnitsTable({ data, optionsPromise }) {
  return (
    <>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Name</th>
            <th scope="col">Max ID</th>
            <th scope="col">Max status</th>
            <th scope="col">App</th>
            <th scope="col">Platform</th>
            <th scope="col">Ad format</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.results.map((adUnit) => (
            <tr key={adUnit.id}>
              <th scope="row">{adUnit.id}</th>
              <td>{adUnit.name}</td>
              <td>{adUnit.maxId}</td>
              <td>
                <ActiveIndicator active={adUnit.maxStatus} />
              </td>
              <td>{adUnit.app}</td>
              <td>{adUnit.platform}</td>
              <td>{adUnit.adFormat}</td>
              <td>
                <Link to={`/ad-units/${adUnit.id}`}>
                  <IconButton.Edit />
                </Link>
                <DuplicateButton
                  prototype={adUnit}
                  optionsPromise={optionsPromise}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination data={data} />
    </>
  );
}

export default AdUnitsPage;
