import classnames from "classnames";
import React from "react";

function NavigationItem({ children, minimized, title }) {
  return (
    <li
      className={classnames("nav__item nav-item", { minimized })}
      title={title}
    >
      {children}
    </li>
  );
}

export default NavigationItem;
