import { useField } from "formik";
import React from "react";
import { Col, Row } from "react-bootstrap";

import Form from "~/components/form";
import DatePickerForm from "~/components/form/date-picker";
import Select from "~/components/form/select";
import SubmitButton from "~/components/form/submit";
import WeekdayPicker from "~/components/form/weekdays";
import Spinner from "~/components/spinner";
import { useAxios } from "~/utils";

const recurrencePeriodUnitOptions = [
  { value: "day", label: "Day" },
  { value: "week", label: "Week" },
];

function AdUnitScheduler({ settingsId }) {
  const [{ data, loading, error }, reFetch] = useAxios(
    `api/unit-settings/${settingsId}/schedule`
  );
  if (loading) return <Spinner />;
  if (error) return "Snap! Some error has happened!";
  return (
    <AdUnitSchedulerForm
      settingsId={settingsId}
      scheduleData={data}
      onEdit={reFetch}
    />
  );
}

function getInitial(settingsId, scheduleData) {
  if (!scheduleData) {
    return {
      setting: settingsId,
      schedule: null,
      recurrenceRules: {
        isRecurring: false,
        periodAmount: 1,
        periodUnit: "day",
        weekdays: null,
      },
    };
  }
  return {
    ...scheduleData,
    setting: settingsId,
    schedule: new Date(Date.parse(scheduleData.schedule)),
  };
}

function AdUnitSchedulerForm({ settingsId, scheduleData, onEdit }) {
  const isEditSchedule = !!scheduleData;
  let axiosSubmitRule = { method: "POST", url: "api/settings-schedule" };
  if (isEditSchedule) {
    axiosSubmitRule = {
      method: "PUT",
      url: `api/settings-schedule/${scheduleData.id}`,
    };
  }
  const handleSubmitSchedule = useAxios(axiosSubmitRule, { manual: true })[1];

  return (
    <>
      <Row className="px-3 my-3">
        <Col className="me-auto">
          <h5 className="">Schedule</h5>
        </Col>
      </Row>
      <Row>
        <Form
          initialValues={getInitial(settingsId, scheduleData)}
          onSubmit={(data) =>
            handleSubmitSchedule({ data }).then(() => {
              onEdit();
            })
          }
        >
          <Row>
            <Col sm={4}>
              <Row>
                <Col md={3} style={{ textAlign: "end", alignSelf: "center" }}>
                  <div>Date and Time</div>
                </Col>
                <Col>
                  <DatePickerForm
                    name="schedule"
                    showTimeSelect
                    dateFormat="MM/dd/yyyy HH:mm"
                    timeFormat="HH:mm"
                  />
                </Col>
              </Row>
              <Form.Group as={Row} className="mt-3" controlId="isRecurring">
                <Col md={3} style={{ textAlign: "end", alignSelf: "center" }}>
                  <Form.Label name="recurrenceRules.isRecurring">
                    Recurring
                  </Form.Label>
                </Col>
                <Col>
                  <Form.Check
                    type="switch"
                    name="recurrenceRules.isRecurring"
                  />
                </Col>
              </Form.Group>
              <Row>
                <Col>
                  <Form.Control.ErrorFeedback name="recurrenceRules.isRecurring" />
                </Col>
              </Row>
            </Col>
            <RecurrenceEditor />
          </Row>
          <Row className="p-3">
            <Col className="d-flex justify-content-end">
              <SubmitButton />
            </Col>
          </Row>
        </Form>
      </Row>
    </>
  );
}

function RecurrenceEditor() {
  const isRecurring = useField("recurrenceRules.isRecurring")[0].value;
  if (!isRecurring) return null;
  return (
    <Col sm={{ span: 4, offset: 1 }}>
      <Row>
        <Col sm={3} style={{ textAlign: "end", alignSelf: "center" }}>
          Repeat every
        </Col>
        <Col sm={3}>
          <Form.Control
            className="p-2"
            type="number"
            name="recurrenceRules.periodAmount"
          />
        </Col>
        <Col sm={4}>
          <Select
            name="recurrenceRules.periodUnit"
            options={recurrencePeriodUnitOptions}
          />
        </Col>
      </Row>
      <WeekdayEditor />
    </Col>
  );
}

function WeekdayEditor() {
  const recurrencePeriodUnit = useField("recurrenceRules.periodUnit")[0].value;
  if (recurrencePeriodUnit !== "week") return null;
  return (
    <Row className="mt-2">
      <Col sm={3} style={{ textAlign: "end", alignSelf: "center" }}>
        Repeat on
      </Col>
      <Col className="pt-2">
        <WeekdayPicker
          name="recurrenceRules.weekdays"
          showChars={2}
          textCase="toUpper"
          dayStyle={{
            margin: "5px",
            padding: "5px",
            border: "1px solid #ccc",
            borderRadius: "10px",
          }}
        />
      </Col>
    </Row>
  );
}

export default AdUnitScheduler;
