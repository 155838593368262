import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React from "react";
import { Collapse, Card, Badge, Button } from "react-bootstrap";

import Breadcrumb from "~/base/breadcrumb";
import { useSearchParams } from "~/utils";

import "./page-header.scss";

function PageHeader({
  sticky = true,
  className,
  filter = false,
  filterOnEnd = false,
  filterAlwaysOpened = false,
  children,
  title = null,
}) {
  const [activeFiltersCount, setActiveFiltersCount] = React.useState(0);
  const [filterOpened, setFilterOpened] = React.useState(false);
  const contextValue = React.useMemo(
    () => ({
      setActiveFiltersCount,
      setFilterOpened,
    }),
    [setActiveFiltersCount, setFilterOpened]
  );
  return (
    <filtersContext.Provider value={contextValue}>
      <header
        className={classNames(className, {
          "sticky-top": sticky,
          "page-header__root-sticky": sticky,
        })}
      >
        <div className="d-flex flex-row justify-content-between align-items-start">
          <h4 className="mb-1">{title || <Breadcrumb />}</h4>
          <div className="d-flex justify-content-end">
            {filterOnEnd && children}
            {filter && (
              <Button
                variant={filterOpened ? "info" : "outline-info"}
                className={classNames({ "me-2": !!children })}
                onClick={() => setFilterOpened(!filterOpened)}
              >
                <FontAwesomeIcon className="me-2" icon={faFilter} />
                Filter{" "}
                {activeFiltersCount > 0 && (
                  <Badge bg="secondary" className="ms-2 text-light">
                    {activeFiltersCount}
                  </Badge>
                )}
              </Button>
            )}
            {!filterOnEnd && children}
          </div>
        </div>

        {!filterAlwaysOpened && <hr className="mt-2" />}
        <CollapsibleFilter
          filter={filter}
          opened={filterAlwaysOpened || filterOpened}
        />
      </header>
    </filtersContext.Provider>
  );
}

function useFilters(filterNames) {
  const { setActiveFiltersCount, setFilterOpened } =
    React.useContext(filtersContext);
  const { state } = useSearchParams();
  const activeFiltersCount = filterNames.filter((v) => v in state).length;
  React.useEffect(
    () => setActiveFiltersCount(activeFiltersCount),
    [activeFiltersCount, setActiveFiltersCount]
  );
  return { setFilterOpened, activeFiltersCount };
}

function CollapsibleFilter({ filter, opened }) {
  return (
    filter && (
      <Collapse in={opened}>
        <div>
          <Card className="mb-4">
            <Card.Body>{filter}</Card.Body>
          </Card>
        </div>
      </Collapse>
    )
  );
}

const filtersContext = React.createContext({
  setActiveFiltersCount: () => {},
  setFilterOpened: () => {},
});

export default PageHeader;
export { useFilters };
