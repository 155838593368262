import React from "react";
import { Accordion, Row, Col, Form, InputGroup, Card } from "react-bootstrap";

import CountriesDiff from "./countries-diff";
import getStatusClass from "./status";

function PlacementsAccordion({ placements }) {
  if (placements.length === 0) {
    return "There are no placements.";
  }
  return (
    <>
      <Row className="mx-0 mb-2">
        <Col xs={6}>Placement ID</Col>
        <Col xs={3}>CPM Price</Col>
        <Col xs={3}>Country Targeting</Col>
      </Row>
      <Accordion>
        {placements.map((p) => (
          <Accordion.Item key={p.id} eventKey={p.id}>
            <Accordion.Header className={getStatusClass(p.status)}>
              <Row className="mx-0 cursor-pointer w-100">
                <Col xs={6}>
                  <Form.Control
                    className="cursor-pointer"
                    type="text"
                    value={p.id}
                    disabled
                  />
                </Col>
                <Col xs={3}>
                  <InputGroup>
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control
                      className="cursor-pointer"
                      type="text"
                      value={p.cpm}
                      disabled
                    />
                  </InputGroup>
                </Col>
                <Col xs={3}>
                  <Form.Control
                    className="cursor-pointer"
                    type="text"
                    value={p.countriesFilter || "N/A"}
                    disabled
                  />
                </Col>
              </Row>
            </Accordion.Header>
            <Accordion.Collapse mountOnEnter eventKey={p.id}>
              <Card.Body className="p-2">
                <CountriesDiff countries={p.countries} />
              </Card.Body>
            </Accordion.Collapse>
          </Accordion.Item>
        ))}
      </Accordion>
    </>
  );
}

export default PlacementsAccordion;
