import React from "react";
import { Toast, ToastContainer, ProgressBar } from "react-bootstrap";

import { defaultAlertTimeout } from "~/constants";
import { useTimeoutProgress } from "~/utils/time";

// translation from django flash message levels to bootstrap bg-color
const translation = {
  error: "danger",
  debug: "secondary",
};

function useAlert() {
  const { addAlert } = React.useContext(alertsContext);
  return addAlert;
}

function AlertsContainer() {
  const { alerts, removeAlert } = React.useContext(alertsContext);
  if (alerts.length === 0) return false;
  return (
    <ToastContainer
      className="p-3"
      placement="top-end"
      style={{
        top: 0,
        right: 0,
        minWidth: 300,
        zIndex: 1100,
        position: "fixed",
      }}
    >
      {alerts.map((a) => (
        <AlertMessage key={a.id} onClose={() => removeAlert(a.id)} alert={a} />
      ))}
    </ToastContainer>
  );
}

function AlertMessage({ onClose, alert }) {
  return (
    <Toast onClose={onClose}>
      <Toast.Header>
        <div className={`p-2 me-2 rounded bg-${alert.variant}`} />
        <small className="me-auto">TLC</small>
      </Toast.Header>
      <Toast.Body>
        <strong>{alert.message}</strong>
        {!!alert.timeout && (
          <TimeoutProgress variant={alert.variant} timeout={alert.timeout} />
        )}
      </Toast.Body>
    </Toast>
  );
}

function TimeoutProgress({ variant, timeout }) {
  // bootstrap progress bar has transition set to 0.6s (--bs-progress-bar-transition var)for smooth visual updates
  // need to subtract that value from timeout for animation to end at proper time
  const timeoutProgress = useTimeoutProgress(timeout - 600);
  return (
    <ProgressBar
      className="mt-1"
      style={{ height: "3px" }}
      variant={variant}
      now={timeoutProgress * 100}
    />
  );
}

function AlertProvider({ children }) {
  const auth = useAlertsImplementation();
  return (
    <alertsContext.Provider value={auth}>
      <AlertsContainer />
      {children}
    </alertsContext.Provider>
  );
}

const alertsContext = React.createContext();

function useAlertsImplementation() {
  const nextId = React.useRef(1);
  const [alerts, setAlerts] = React.useState([]);

  const removeAlert = React.useCallback((id) => {
    setAlerts((prevAlerts) => prevAlerts.filter((a) => a.id !== id));
  }, []);

  const addAlert = React.useCallback(
    (message, messageVariant, timeout = defaultAlertTimeout) => {
      const variant = translation[messageVariant] || messageVariant;
      const entry = { message, variant, timeout, id: nextId.current };
      nextId.current += 1;
      setAlerts((prevAlerts) => [...prevAlerts, entry]);
      if (timeout) {
        setTimeout(() => {
          removeAlert(entry.id);
        }, timeout);
      }
    },
    [removeAlert]
  );

  return {
    addAlert,
    removeAlert,
    alerts,
  };
}

export { AlertProvider, useAlert };
