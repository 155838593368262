import * as Yup from "yup";

/* eslint-disable no-template-curly-in-string */
const Schema = Yup.object().shape({
  enabled: Yup.boolean().required("Required!"),
  launchTarget: Yup.number()
    .required("Required!")
    .min(0, "Should be >= ${min}"),
  pauseTarget: Yup.number().required("Required!").min(0, "Should be >= ${min}"),
  pauseTargetLowQuarter: Yup.number()
    .required("Required!")
    .min(0, "Should be >= ${min}"),
});
/* eslint-enable no-template-curly-in-string */

export default Schema;
