import React from "react";
import { Button } from "react-bootstrap";

import { useAlert } from "~/base/alerts";
import { axios } from "~/utils";

function CreateVersionButton({ adUnit, onCreate, small = false }) {
  const [loading, setLoading] = React.useState(false);
  const addAlert = useAlert();

  const onClick = () => {
    setLoading(true);
    if (!loading) {
      axios({
        url: `api/ad-units/${adUnit.id}/create-version`,
        method: "POST",
      })
        .then((r) => {
          r.data.messages.forEach(([variant, message]) => {
            addAlert(message, variant);
          });
          onCreate();
        })
        .catch(() => {
          addAlert("Error while creating a new version", "error");
        })
        .finally(() => setLoading(false));
    }
  };
  return (
    <Button disabled={loading} onClick={onClick} size={small ? "sm" : null}>
      Create version
    </Button>
  );
}

export default CreateVersionButton;
