function joinPath(prefix, current) {
  if (!prefix) return current;
  let parent = prefix;
  parent = parent.endsWith("*") ? parent.slice(0, -1) : parent;
  if (!current) return parent;
  parent = parent.endsWith("/") ? parent : `${parent}/`;
  return `${parent}${current}`;
}

function extractSearch(url) {
  const pos = url.indexOf("?");
  if (pos === -1) return "";
  return url.slice(pos);
}

export { joinPath, extractSearch };
