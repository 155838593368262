import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Modal, Col, Row, Button } from "react-bootstrap";
import * as Yup from "yup";

import Form from "~/components/form";
import SubmitButton from "~/components/form/submit";
import { axios } from "~/utils";

import CountryTierSelect from "../country-tier/select";
import { useOptions } from "../options";
import useConfigValidation from "./config-validation";
import EditSchema from "./validation-schema";

const schema = Yup.object()
  .shape({
    countryTier: Yup.array()
      .min(1, "Field must have at least 1 items")
      .of(Yup.mixed())
      .when(["network", "existingSettings"], (network, existingSettings, s) => {
        const existing = { "all~all": "all" };
        existingSettings
          .filter((v) => v.countryTier || v.network)
          .forEach((v) => {
            const id = v.countryTier ? v.countryTier.id : "all";
            const tierKey = `${v.network || "all"}~${id}`;
            existing[tierKey] = true;
            if (!v.countryTier) return;
            v.countryTier.countries.forEach((c) => {
              const countryKey = `${v.network || "all"}~${c}`;
              existing[countryKey] = v.countryTier.name || c;
            });
          });

        return s.test(
          "disallow-existing-settings",
          "This setting already exists",
          (value, ctx) =>
            value
              .map((c) => {
                const key = `${network}~${c}`;
                if (!existing[key]) return null;
                if (typeof c === "number") {
                  return ctx.createError({
                    message: `Setting for ${network}/this tier already exists`,
                  });
                }
                if (existing[key] === c || c === "all") {
                  return ctx.createError({
                    message: `Setting for '${network}/${c}' already exists`,
                  });
                }
                const tier = existing[key];
                return ctx.createError({
                  message: `Setting for '${network}/${c}' already exists (in '${tier}' tier)`,
                });
              })
              .find((v) => v) || true
        );
      }),
    network: Yup.string(),
  })
  .concat(EditSchema);

function NetworkCreateModal({ existingSettings, unitId, onCreate }) {
  const [show, setShow] = React.useState(false);
  const options = useOptions();
  const [validate, renderModal] = useConfigValidation(unitId);

  const closeModal = () => setShow(false);
  const showModal = () => setShow(true);
  return (
    <>
      <Button variant="success" onClick={showModal}>
        <span className="me-2">
          <FontAwesomeIcon icon={faCirclePlus} />
        </span>
        Add
      </Button>
      {renderModal()}
      <Modal show={show} onHide={closeModal} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Create network setting</Modal.Title>
        </Modal.Header>
        <Form
          form={false}
          validationSchema={schema}
          initialValues={{
            countryTier: [],
            network: "all",
            enabled: true,
            activeMinimum: 1,
            activeMaximum: 10,
            lowestPlacementActive: true,
            launchAllPlacements: false,
            existingSettings,
          }}
          onSubmit={({ network, existingSettings: noShadow, ...rest }) =>
            axios({
              method: "POST",
              url: `api/ad-units/${unitId}/network-params`,
              data: {
                network: network === "all" ? null : network,
                ...rest,
              },
            })
              .then(() => closeModal())
              .then(() => validate())
              .then(() => {
                onCreate();
              })
          }
        >
          <Modal.Body className="px-5">
            <Form.InnerForm>
              <Form.NonFieldErrors />

              <InputRow label="Country" name="countryTier">
                <CountryTierSelect name="countryTier" />
              </InputRow>

              <InputRow label="Network" name="network">
                <Form.Select options={options.networks} name="network" />
              </InputRow>

              <InputRow label="Enabled" name="enabled">
                <Form.Check name="enabled" />
              </InputRow>

              <InputRow label="Active minimum" name="activeMinimum">
                <Form.Control type="number" name="activeMinimum" />
              </InputRow>

              <InputRow label="Active maximum" name="activeMaximum">
                <Form.Control type="number" name="activeMaximum" />
              </InputRow>

              <InputRow
                label="Lowest Placement Active"
                name="lowestPlacementActive"
              >
                <Form.Check name="lowestPlacementActive" />
              </InputRow>

              <InputRow
                label="Launch All Placements"
                name="launchAllPlacements"
              >
                <Form.Check name="launchAllPlacements" />
              </InputRow>

              {/* this input is needed to allow to submit form by pressing enter */}
              <input type="submit" className="d-none" />
            </Form.InnerForm>
          </Modal.Body>
          <Modal.Footer>
            <SubmitButton title="Create" />
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

function InputRow({ label, name, children }) {
  return (
    <Form.Group as={Row} className="my-3" controlId={name}>
      <Form.Label column sm={4}>
        {label}
      </Form.Label>
      <Col sm={8} className="d-flex flex-wrap align-items-center">
        <div className="w-100">{children}</div>
        <Form.Control.ErrorFeedback name={name} />
      </Col>
    </Form.Group>
  );
}

export default NetworkCreateModal;
