const loadScript = (jsSrc) =>
  new Promise((resolve, reject) => {
    const element = document.createElement("script");
    element.src = jsSrc;
    element.onerror = reject;
    element.onload = resolve;
    document.head.appendChild(element);
  });

export { loadScript };
