function getStatusClass(status) {
  if (status === "changed") {
    return "diff-changed-row";
  }
  if (status === "removed") {
    return "diff-removed-row";
  }
  if (status === "added") {
    return "diff-added-row";
  }
  return "";
}

export default getStatusClass;
