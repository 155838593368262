import React from "react";
import { Modal, Button } from "react-bootstrap";

function useConfirmation(defaultElement = null) {
  const privateConfirm = React.useContext(confirmContext);
  return (callback, element = null) =>
    privateConfirm(callback, element || defaultElement);
}

function ConfirmProvider({ children }) {
  const { visible, accept, reject, element, confirm } =
    useConfirmImplementation();
  return (
    <confirmContext.Provider value={confirm}>
      {children}
      <ConfirmModal visible={visible} accept={accept} reject={reject}>
        {element}
      </ConfirmModal>
    </confirmContext.Provider>
  );
}

function ConfirmModal({ visible, accept, reject, children }) {
  return (
    <Modal show={visible} centered onHide={reject}>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={reject}>
          Cancel
        </Button>
        <Button variant="primary" onClick={accept}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function useConfirmImplementation() {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const visible = !!state.element;
  const reject = () => {
    dispatch({ type: "reset" });
    state.resolve();
  };
  const accept = () => {
    dispatch({ type: "reset" });
    const rv = state.callback();
    if (rv && rv.then && typeof rv.then === "function") {
      rv.finally(() => state.resolve());
    } else {
      state.resolve();
    }
  };
  const confirm = React.useCallback(
    (callback, element) =>
      new Promise((resolve) => {
        dispatch({ type: "set", callback, element, resolve });
      }),
    [dispatch]
  );

  return {
    visible,
    accept,
    reject,
    element: state.element,
    confirm,
  };
}

function reducer(state, action) {
  const { type, ...rest } = action;
  if (type === "reset") return initialState;
  if (type === "set") return rest;
  return state;
}

const initialState = {
  element: false,
  callback: null,
  resolve: null,
};

const confirmContext = React.createContext();

export { useConfirmation, ConfirmProvider };
