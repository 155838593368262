function camelToSnake(string) {
  return string
    .split(/(?=[A-Z])/)
    .join("_")
    .toLowerCase();
}

function snakeToCamel(string) {
  return string
    .toLowerCase()
    .replace(/([_][a-z])/g, (group) => group.toUpperCase().replace("_", ""));
}

function camelToKebab(string) {
  return string
    .split(/(?=[A-Z])/)
    .join("-")
    .toLowerCase();
}

function kebabToCamel(string) {
  return string
    .toLowerCase()
    .replace(/([-][a-z])/g, (group) => group.toUpperCase().replace("-", ""));
}

function camelJoin(...parts) {
  const [first, ...rest] = parts.filter((p) => !!p);
  const restUpperCased = rest.map(
    (v) => v.charAt(0).toUpperCase() + v.slice(1)
  );
  return [first, ...restUpperCased].join("");
}

function camelRemovePrefix(string, prefix) {
  if (!string.startsWith(prefix)) return string;
  const result = string.slice(prefix.length);
  if (!result) return result;
  return result.charAt(0).toLowerCase() + result.slice(1);
}

export {
  camelToSnake,
  snakeToCamel,
  camelToKebab,
  kebabToCamel,
  camelJoin,
  camelRemovePrefix,
};
