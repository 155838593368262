import React from "react";
import { Await } from "react-router-dom";

import Spinner from "~/components/spinner";

function SimpleAwaiter({ promise, children }) {
  return (
    <React.Suspense
      fallback={<Spinner className="mx-auto" animation="border" />}
    >
      <Await
        resolve={promise}
        errorElement={<div>Failed to load the data 😔</div>}
      >
        {(data) => (typeof children === "function" ? children(data) : children)}
      </Await>
    </React.Suspense>
  );
}

export default SimpleAwaiter;
