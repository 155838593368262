import React from "react";

function useUserData() {
  return React.useContext(userDataContext);
}

function usePermissionChecker(checkAll = true) {
  const data = useUserData();
  const check = React.useCallback(
    (permissions) => {
      const perms = Array.isArray(permissions) ? permissions : [permissions];
      const method = checkAll ? "every" : "some";
      return data && perms[method]((v) => data.permissions.includes(v));
    },
    [data, checkAll]
  );
  return check;
}

function useHasPermission(permissions) {
  const check = usePermissionChecker();
  return check(permissions);
}

function UserDataProvider({ children, value }) {
  return (
    <userDataContext.Provider value={value}>
      {children}
    </userDataContext.Provider>
  );
}

const userDataContext = React.createContext({
  loading: true,
  error: null,
  data: null,
});

export default useUserData;
export { UserDataProvider, useHasPermission, usePermissionChecker };
