import React from "react";

const cpmColor = "#FEF4456D";
const dateColor = "#CEE7416D";

function Cpm({ value, formatter }) {
  return (
    <span style={{ backgroundColor: cpmColor, whiteSpace: "pre" }}>
      {formatter(value)}
    </span>
  );
}

function getNumberFormatter(data) {
  if (!data) return (v) => v;
  const values = [
    ...data.map((e) => e.cpm),
    ...data.map((e) => e.choices.map((c) => c.cpm)).flat(),
  ].map((v) => (v ? v.toFixed(2).length + 1 : 1));
  const maxLength = Math.max(...values);

  return (v) => {
    const rendered = v ? `$${v.toFixed(2)}` : "-";
    return rendered.padStart(maxLength);
  };
}

function renderLabel(entry, numberFormatter) {
  return (
    <span>
      {entry.id} / <Cpm value={entry.cpm} formatter={numberFormatter} /> /{" "}
      {renderName(entry.parsedName, entry.isHighlighted)}
    </span>
  );
}

function renderName(parsedName, isHighlighted = false) {
  if (parsedName.length === 1) {
    return parsedName[0];
  }
  const firstPart = (
    <>
      {parsedName[0]}_<Cpm value={parsedName[1]} formatter={(v) => `$${v}`} />
    </>
  );
  if (parsedName.length === 2) {
    return firstPart;
  }
  const style = isHighlighted ? { backgroundColor: dateColor } : {};
  return (
    <>
      {firstPart}
      <span style={style}>{parsedName[2]}</span>
    </>
  );
}

export { getNumberFormatter, Cpm, renderLabel };
