/* eslint-disable camelcase */
import React from "react";
import { Stack, Button } from "react-bootstrap";

import { redirectToSSO } from "~/base/auth";

function AuthErrorPage() {
  return (
    <Stack className="d-flex justify-content-center align-items-center vh-100">
      <h1>Something went wrong during login.</h1>
      <h1>Please try again or contact MAD Team.</h1>
      <Button
        variant="outline-primary"
        className="mt-4"
        onClick={redirectToSSO}
        size="lg"
      >
        Go to login page
      </Button>
    </Stack>
  );
}

export default AuthErrorPage;
