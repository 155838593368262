import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormikContext } from "formik";
import React from "react";
import { Modal, Table } from "react-bootstrap";

import Form from "~/components/form";
import SubmitButton from "~/components/form/submit";
import { axios } from "~/utils";

import CountryTierView from "../country-tier/view";

function useConfigValidation(unitId) {
  const [overlaps, setOverlaps] = React.useState([]);
  const resolveRef = React.useRef(null);

  const renderModal = () => (
    <OverlapsModal overlaps={overlaps} onSubmit={resolveRef.current} />
  );
  const validate = () =>
    axios(`api/ad-units/${unitId}/network-params/overlaps`).then((r) => {
      if (r.data.length === 0) return null;
      setOverlaps(r.data);
      return new Promise((resolve) => {
        resolveRef.current = resolve;
      })
        .then((toCreate) => {
          if (toCreate.length === 0) return null;
          return Promise.all(
            toCreate.map((data) =>
              axios({
                method: "POST",
                url: `api/ad-units/${unitId}/network-params`,
                data,
              })
            )
          );
        })
        .finally(() => {
          resolveRef.current = null;
          setOverlaps([]);
        });
    });

  return [validate, renderModal];
}

function OverlapsModal({ overlaps, onSubmit }) {
  if (overlaps.length === 0) return null;
  return (
    <Modal show centered size="lg">
      <Form
        form={false}
        initialValues={{ toCreate: [] }}
        onSubmit={(values) => {
          if (onSubmit) {
            onSubmit(values.toCreate.map(deserializeEntry));
          }
        }}
      >
        <Modal.Body className="px-5">
          <p>
            The configuration is ambiguous. There are multiple settings which
            are eligible for the same area (<b>Overlap</b>). The <b>Winner</b>{" "}
            setting will be <b>used</b>, the <b>Competitor</b> setting will be{" "}
            <b>ignored</b>.
          </p>
          <p>
            If you are fine with it, press <b>proceed</b>, otherwise select{" "}
            <b>overlaps</b> for which explicit settings should be created.
          </p>
          <Table bordered size="sm">
            <thead>
              <tr>
                <th scope="col">Overlap</th>
                <th scope="col">Winner</th>
                <th scope="col">Competitor</th>
                <th scope="col">Create?</th>
              </tr>
            </thead>
            <tbody>
              {overlaps.map((entry, i) => (
                <tr key={entry.area ? serializeEntry(entry.area) : i}>
                  <td>{entry.area ? renderEntry(entry.area) : "-"}</td>
                  <td>{renderEntry(entry.winner)}</td>
                  <td>{renderEntry(entry.competitor)}</td>
                  <td>
                    {entry.area ? (
                      <Form.Check
                        name="toCreate"
                        value={serializeEntry(entry.area)}
                      />
                    ) : (
                      <FontAwesomeIcon
                        style={{ color: "red" }}
                        title="This case is too complex and is not supported. Please remove one of the clashing setting."
                        icon={faTriangleExclamation}
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <AdaptiveSubmitButton />
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

function renderEntry(entry) {
  const { countryTier } = entry;
  const network = entry.network || "all";
  return (
    <>
      {network} (<CountryTierView value={countryTier} />)
    </>
  );
}

function serializeEntry(entry) {
  const countryTier = entry.countryTier.id;
  const network = entry.network || "all";
  return `${countryTier}~${network}`;
}

function deserializeEntry(input) {
  const [countryTier, network] = input.split("~", 2);
  return {
    countryTier: [Number(countryTier)],
    network: network === "all" ? null : network,
  };
}

function AdaptiveSubmitButton() {
  const { values } = useFormikContext();
  const extraSettingsCount = values.toCreate.length;
  const willCreateSettings = extraSettingsCount > 0;
  const title = willCreateSettings
    ? `Create ${extraSettingsCount} settings`
    : "Proceed";
  return <SubmitButton icon={willCreateSettings} title={title} />;
}

export default useConfigValidation;
