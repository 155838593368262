import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { useAsyncValue } from "react-router-dom";

import Form from "~/components/form";
import SubmitButton from "~/components/form/submit";
import IconButton from "~/components/icon-button";
import SimpleAwaiter from "~/components/simple-awaiter";
import { axios } from "~/utils";

function DuplicateButton({ prototype, optionsPromise }) {
  const [showModal, setShowModal] = React.useState(false);
  const [messages, setMessages] = React.useState([]);

  const onClose = () => {
    setShowModal(false);
    setMessages([]);
  };

  return (
    <>
      <IconButton.Duplicate onClick={() => setShowModal(true)} />
      <Modal show={showModal} onHide={onClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Copy ad unit</Modal.Title>
        </Modal.Header>
        {messages.length ? (
          <Modal.Body>
            {messages.map((m) => (
              <p key={m}>{m}</p>
            ))}
          </Modal.Body>
        ) : (
          <SimpleAwaiter promise={optionsPromise}>
            <ModalForm
              prototype={prototype}
              onSuccess={(values) => setMessages(values)}
            />
          </SimpleAwaiter>
        )}
      </Modal>
    </>
  );
}

function ModalForm({ prototype, onSuccess }) {
  const data = useAsyncValue();
  const onSubmit = (values) =>
    axios({
      url: "api/copy-ad-unit",
      method: "POST",
      data: values,
    }).then((r) => onSuccess(r.data.messages.map((e) => e[1])));
  const formatOptions = data.formats.filter((f) => f.value !== "");
  const appOptions = data.apps.filter((f) => f.value !== "");
  const platformOptions = data.platforms.filter((p) => p.value !== "");
  return (
    <Form
      form={false}
      initialValues={{
        createBeforeCopy: false,
        targetId: "",
        name: prototype.name,
        app: prototype.appId,
        platform: prototype.platformId,
        adFormat: prototype.adFormatId,
        maxId: prototype.maxId,
      }}
      onSubmit={onSubmit}
    >
      {({ values }) => (
        <>
          <Modal.Body className="px-5">
            <Form.InnerForm>
              <Form.NonFieldErrors />

              <InputRow label="Create before copy" name="createBeforeCopy">
                <Form.Check name="createBeforeCopy" />
              </InputRow>

              {!values.createBeforeCopy && (
                <InputRow label="Target max ID" name="targetId">
                  <Form.Control name="targetId" />
                </InputRow>
              )}

              {values.createBeforeCopy && (
                <>
                  <InputRow label="Name" name="name">
                    <Form.Control name="name" />
                  </InputRow>

                  <InputRow label="App" name="app">
                    <Form.Select
                      name="app"
                      className="w-100"
                      options={appOptions}
                    />
                  </InputRow>

                  <InputRow label="Platform" name="platform">
                    <Form.Select
                      name="platform"
                      className="w-100"
                      options={platformOptions}
                    />
                  </InputRow>

                  <InputRow label="Ad format" name="adFormat">
                    <Form.Select
                      name="adFormat"
                      className="w-100"
                      options={formatOptions}
                    />
                  </InputRow>
                </>
              )}

              {/* this input is needed to allow to submit form by pressing enter */}
              <input type="submit" className="d-none" />
            </Form.InnerForm>
          </Modal.Body>
          <Modal.Footer>
            <SubmitButton title="Copy ad unit" />
          </Modal.Footer>
        </>
      )}
    </Form>
  );
}

function InputRow({ label, name, children }) {
  return (
    <Form.Group as={Row} className="mb-3" controlId={name}>
      <Form.Label column sm={4}>
        {label}
      </Form.Label>
      <Col sm={8} className="d-flex flex-wrap align-items-center">
        <div className="w-100">{children}</div>
        <Form.Control.ErrorFeedback name={name} />
      </Col>
    </Form.Group>
  );
}

export default DuplicateButton;
