import React from "react";

import IconButton from "~/components/icon-button";
import { useAlert, axios, useConfirmation } from "~/utils";

import CountryTierView from "../country-tier/view";
import useConfigValidation from "./config-validation";

function NetworkDeleteButton({ data, unitId, onDelete }) {
  const [loading, setLoading] = React.useState(false);
  const [validate, renderModal] = useConfigValidation(unitId);
  const addAlert = useAlert();
  const confirmDelete = useConfirmation();

  const onConfirm = () => {
    setLoading(true);
    if (!loading) {
      axios({
        method: "DELETE",
        url: `api/ad-units/${unitId}/network-params/${data.id}`,
      })
        .then(() => validate())
        .then(() => onDelete())
        .catch(() => {
          addAlert("Error while deleting setting", "error");
        })
        .finally(() => setLoading(false));
    }
  };

  const onClick = () =>
    confirmDelete(
      onConfirm,
      <p>
        Are you sure want to delete setting for network{" "}
        <i>
          {data.network || "all"} (<CountryTierView value={data.countryTier} />)
        </i>
        ?
      </p>
    );

  if (!data.isRemovable) return null;
  return (
    <>
      <IconButton.Delete disabled={loading} size="sm" onClick={onClick} />
      {renderModal()}
    </>
  );
}

export default NetworkDeleteButton;
