import { faXmark, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

<FontAwesomeIcon icon={faXmark} />;

function Boolean({ value }) {
  const icon = value ? faCheck : faXmark;
  return <FontAwesomeIcon icon={icon} />;
}

export default Boolean;
