import React from "react";

import IconButton from "~/components/icon-button";
import { useAlert, axios, useConfirmation } from "~/utils";

import CountryTierView from "../country-tier/view";

function CountryDeleteButton({ data, unitId, onDelete }) {
  const [loading, setLoading] = React.useState(false);
  const addAlert = useAlert();
  const confirmDelete = useConfirmation();

  const onConfirm = () => {
    setLoading(true);
    if (!loading) {
      axios({
        method: "DELETE",
        url: `api/ad-units/${unitId}/country-params/${data.id}`,
      })
        .then(() => onDelete())
        .catch(() => {
          addAlert("Error while deleting setting", "error");
        })
        .finally(() => setLoading(false));
    }
  };

  const onClick = () =>
    confirmDelete(
      onConfirm,
      <p>
        Are you sure want to delete setting for countries
        <i>
          <CountryTierView value={data.countryTier} />
        </i>
        ?
      </p>
    );

  if (!data.isRemovable) return null;
  return <IconButton.Delete disabled={loading} size="sm" onClick={onClick} />;
}

export default CountryDeleteButton;
