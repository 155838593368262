import React from "react";
import { Button } from "react-bootstrap";

import { useAlert, axios, useConfirmation } from "~/utils";

function RestoreVersionButton({ adUnit, target, last, onRestore }) {
  if (!target) return null;
  return (
    <RestoreVersionButtonImpl
      adUnit={adUnit}
      target={target}
      last={last}
      onRestore={onRestore}
    />
  );
}

function RestoreVersionButtonImpl({ adUnit, target, last, onRestore }) {
  const [loading, setLoading] = React.useState(false);
  const addAlert = useAlert();
  const confirmRestore = useConfirmation();

  if (!target) {
    return null;
  }

  const onConfirm = () => {
    setLoading(true);
    if (!loading) {
      axios({
        url: `api/ad-units/${adUnit.id}/restore-version/${target.linkVersion}`,
        method: "POST",
      })
        .then((r) => {
          r.data.messages.forEach(([variant, message]) => {
            addAlert(message, variant);
          });
          onRestore();
        })
        .catch(() => {
          addAlert("Error while restoring version", "error");
        })
        .finally(() => setLoading(false));
    }
  };

  const onClick = () =>
    confirmRestore(
      onConfirm,
      <p>
        Are you sure want to restore version {renderVersion(target)}?
        {last.hasExperiment && renderHint(target, last)}
      </p>
    );

  return (
    <Button disabled={loading} variant="danger" onClick={onClick}>
      Restore
    </Button>
  );
}

function renderVersion(target) {
  return (
    <>
      v{target.version.number}
      {target.isExperiment && "T"} ({target.version.createdAt})
    </>
  );
}

function renderHint(target, last) {
  if (target.version.number === last.number) {
    return (
      <>
        Both ad unit data and experiment data in applovin will be set to v
        {last.number}.
      </>
    );
  }
  if (target.isExperiment) {
    return (
      <>
        New version will have ad unit data from v{last.number} and experiment
        data from v{target.version.number}.
      </>
    );
  }
  return (
    <>
      New version will have ad unit data from v{target.version.number} and
      experiment data from v{last.number}.
    </>
  );
}

export default RestoreVersionButton;
