import React from "react";
import { Row, Col } from "react-bootstrap";
import { useNavigate, useLoaderData } from "react-router-dom";

import PageHeader from "~/components/page-header";
import { axios, extractSearch } from "~/utils";

import CreateVersionButton from "./create-version-button";
import NetworksAccordion from "./networks-accordion";
import RestoreVersionButton from "./restore-version-button";
import VersionSelector from "./version-selector";

import "./index.scss";

function AdUnitVersionsPage() {
  const navigate = useNavigate();
  const data = useLoaderData();
  const onVersionCreate = () => {
    navigate({ search: "" });
  };
  return <VersionsViewer data={data} onVersionCreate={onVersionCreate} />;
}

AdUnitVersionsPage.loader = async function loader({ request, params }) {
  const search = extractSearch(request.url);
  const response = await axios(
    `api/ad-units/${params.adUnitID}/versions${search}`
  );
  return response.data;
};

function VersionsViewer({ data, onVersionCreate }) {
  if (!data.last) {
    return (
      <>
        This ad unit doesn&apos;t have versions yet.
        <CreateVersionButton
          adUnit={data.adUnit}
          small
          onCreate={onVersionCreate}
        />
      </>
    );
  }
  return (
    <>
      <PageHeader />
      <div className="container p-1">
        <Row xs={2} className="my-3">
          <Col>
            <Row>
              <Col xs="auto">
                <VersionSelector
                  selected={data.left}
                  versions={data.versions}
                  left={data.left}
                  right={data.right}
                  side="left"
                />
              </Col>
              <Col xs="auto">
                <RestoreVersionButton
                  adUnit={data.adUnit}
                  target={data.left}
                  side="left"
                  last={data.last}
                  onRestore={onVersionCreate}
                />
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col xs="auto">
                <VersionSelector
                  selected={data.right}
                  versions={data.versions}
                  left={data.left}
                  right={data.right}
                  side="right"
                />
              </Col>
              <Col xs="auto">
                <RestoreVersionButton
                  adUnit={data.adUnit}
                  target={data.right}
                  side="right"
                  last={data.last}
                  onRestore={onVersionCreate}
                />
              </Col>
              <Col xs="auto">
                <CreateVersionButton
                  adUnit={data.adUnit}
                  onCreate={onVersionCreate}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        {data.networks ? (
          <NetworksAccordion networks={data.networks} />
        ) : (
          "Nothing to compare (two versions should be selected)!"
        )}
      </div>
    </>
  );
}
export default AdUnitVersionsPage;
