import React from "react";

import { useVarRef, useOnPageClose } from "./hooks";

function useLocalStorage(key, initialValue) {
  const [state, dispatch] = React.useReducer(
    reducer,
    [key, initialValue],
    getInitialState
  );
  React.useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [state, key]);

  return [state, dispatch];
}

function useLazyLocalStorage(key, initialValue) {
  // same as useLocalStorage, but pushes state to localStorage only on the final dismount
  const [state, dispatch] = React.useReducer(
    reducer,
    [key, initialValue],
    getInitialState
  );
  const stateRef = useVarRef(state);
  const onClose = React.useCallback(() => {
    localStorage.setItem(key, JSON.stringify(stateRef.current));
  }, [key, stateRef]);
  useOnPageClose(onClose);
  return [state, dispatch];
}

function getInitialState(args) {
  const [key, initialValue] = args;
  const item = localStorage.getItem(key);
  if (item === null) return initialValue;
  return JSON.parse(item);
}

function reducer(state, action) {
  return typeof action === "function" ? action(state) : action;
}

export { useLocalStorage, useLazyLocalStorage };
