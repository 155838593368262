import * as Yup from "yup";

const AdUnitEditValidation = Yup.object().shape({
  platform: Yup.number(),
  app: Yup.number(),
  adFormat: Yup.number(),
  isLat: Yup.boolean(),
  pushAsTest: Yup.boolean(),
  includeInRun: Yup.boolean(),
  debug: Yup.boolean(),
});

export default AdUnitEditValidation;
