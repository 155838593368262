import {
  faCircle,
  faCircleCheck,
  faFaceMeh,
  faFaceSadTear,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Accordion, Alert, Table } from "react-bootstrap";

import Spinner from "~/components/spinner";

const colorMap = {
  "In progress": "yellow",
  Success: "green",
  "Partial success": "orange",
  Failed: "red",
  Skipped: "#ADFF2F",
  // this status is not used in other components
  Pending: "grey",
};

const iconMap = {
  "In progress": <Spinner />,
  Success: (
    <FontAwesomeIcon className="fa-8x" color="green" icon={faCircleCheck} />
  ),
  "Partial success": (
    <FontAwesomeIcon className="fa-8x" color="orange" icon={faFaceMeh} />
  ),
  Failed: (
    <FontAwesomeIcon className="fa-8x" color="red" icon={faFaceSadTear} />
  ),
};

function UnitsProgressHeader({ operation, done, total }) {
  let operationName = "processed";
  if (operation === "Refresh") {
    operationName = "swapped";
  }
  if (operation === "Create") {
    operationName = "created";
  }
  return (
    <h5 className="text-center my-4">
      Placements {operationName} for Ad Units {done}/{total}
    </h5>
  );
}

function StatusHeader({ operation, status }) {
  let operationName = "processing";
  if (operation === "Refresh") {
    operationName = "swap";
  }
  if (operation === "Create") {
    operationName = "creation";
  }
  const messageMap = {
    "In progress": `Give us a moment. We are working on the placement ${operationName}`,
    Success: `Bulk placements ${operationName} completed`,
    "Partial success": `Bulk placements ${operationName} partially completed`,
    Failed: `Bulk placements ${operationName} failed`,
  };
  const message = messageMap[status] || messageMap.Failed;
  return (
    <h5 className="text-center my-4">
      <i>{message}</i>
    </h5>
  );
}

function OperationIndicator({ log }) {
  const { operation, isBulk, targetDate } = log;
  const prefix = isBulk ? (
    <>
      <b className="mx-1">Bulk</b> ad unit
    </>
  ) : (
    <>Ad unit</>
  );
  let operationName = "action";
  if (operation === "Refresh") {
    operationName = "swap";
  }
  if (operation === "Create") {
    operationName = "creation";
  }
  return (
    <>
      {prefix} placements <b className="mx-1">{operationName}</b> for{" "}
      <b className="mx-1">{targetDate}</b>
    </>
  );
}

function StatusIcon({ status }) {
  const icon = iconMap[status] || iconMap.Failed;
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ minHeight: "160px" }}
    >
      {icon}
    </div>
  );
}

function StatusIndicator({ status, ...rest }) {
  const color = colorMap[status] || colorMap.Failed;
  return (
    <FontAwesomeIcon title={status} color={color} icon={faCircle} {...rest} />
  );
}

function UnitsAccordion({ units, operation }) {
  return (
    <Accordion>
      {units.map((log) => (
        <Accordion.Item key={log.id} eventKey={log.id}>
          <Accordion.Header>
            <StatusIndicator className="me-2" status={log.status} />
            {log.unit.name} ({log.unit.maxId})<span className="mx-1">on</span>
            <b>{log.createdAt}</b>
          </Accordion.Header>
          <Accordion.Body>
            {operation === "Refresh" && <RefreshTable entries={log.entries} />}
            {operation === "Create" && <CreateTable entries={log.entries} />}
            {log.messages.map(([variant, message, response]) => (
              <Alert
                key={message}
                variant={variant === "error" ? "danger" : variant}
              >
                {message}
                {!!response && (
                  <>
                    <hr />
                    {response}
                  </>
                )}
              </Alert>
            ))}
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
}

function RefreshTable({ entries }) {
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th scope="col">Group</th>
          <th scope="col">Initial</th>
          <th scope="col">Final</th>
        </tr>
      </thead>
      <tbody>
        {entries.map((entry) => (
          <tr key={`${entry.next}-${entry.group}`}>
            <td>{entry.group}</td>
            <td className="font-monospace">
              {entry.previous} / ${entry.previousCpm}
            </td>
            <td className="font-monospace">
              {entry.next} / ${entry.nextCpm} / {entry.nextName}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

function CreateTable({ entries }) {
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Cpm</th>
          <th scope="col">Id</th>
          <th scope="col">Status</th>
        </tr>
      </thead>
      <tbody>
        {entries.map((entry) => (
          <tr key={`${entry.nextName}`}>
            <td>{entry.nextName}</td>
            <td>${entry.nextCpm}</td>
            <td>{entry.next}</td>
            <td>
              <StatusIndicator status={entry.status} />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

export {
  UnitsProgressHeader,
  StatusHeader,
  StatusIcon,
  StatusIndicator,
  OperationIndicator,
  UnitsAccordion,
};
