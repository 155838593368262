import React from "react";
import { Row, Col } from "react-bootstrap";

function Label({ className, text, labelSize, fieldSize, children, direction }) {
  const labelProps = {};
  const renderLabel = () => (
    <label className="fw-bold form-label" {...labelProps}>
      {text}
    </label>
  );

  return direction === "vertical" ? (
    <div className={`${className} form-group d-flex flex-column`}>
      <div>{renderLabel()}</div>
      <div>{children}</div>
    </div>
  ) : (
    <Row className={`${className} form-group`}>
      <Col xs={labelSize}>{renderLabel()}</Col>

      <Col xs={fieldSize}>{children}</Col>
    </Row>
  );
}

Label.defaultProps = {
  direction: "horizontal",
  labelSize: 4,
  fieldSize: 6,
  className: "",
};

export default Label;
