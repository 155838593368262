import React from "react";

function useVarRef(value) {
  // hook to pass frequently changed value to callback/effect and not to cause frequent effect tearDown/setUp
  const ref = React.useRef(value);
  React.useEffect(() => {
    ref.current = value;
  });
  return ref;
}

function useOnPageClose(callback) {
  React.useEffect(() => {
    window.addEventListener("beforeunload", callback);
    return () => {
      window.removeEventListener("beforeunload", callback);
    };
  }, [callback]);
}

export { useVarRef, useOnPageClose };
