import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormikContext } from "formik";
import Button from "react-bootstrap/Button";

function SubmitButton({
  title = "Save",
  icon = true,
  disabled = false,
  ...rest
}) {
  const { isSubmitting, submitForm } = useFormikContext();

  return (
    <Button
      type="submit"
      disabled={disabled || isSubmitting}
      onClick={submitForm}
      {...rest}
    >
      {icon && <FontAwesomeIcon className="me-2" icon={faFloppyDisk} />}
      {title}
    </Button>
  );
}

export default SubmitButton;
