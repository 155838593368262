import { faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import React from "react";

import NavigationIcon from "./icon";
import NavigationItem from "./item";

function NavigationExternalLink({
  to,
  title,
  className,
  icon,
  samePage = false,
  minimized = false,
}) {
  const extra = samePage ? {} : { target: "_blank" };
  const postfix = samePage ? (
    false
  ) : (
    <FontAwesomeIcon className="text-dark ms-2" icon={faUpRightFromSquare} />
  );
  return (
    <NavigationItem minimized={minimized}>
      <a
        className={classnames("nav__link nav-link", className, { minimized })}
        href={to}
        title={title}
        {...extra}
      >
        <NavigationIcon icon={icon} minimized={minimized} />
        {!minimized && (
          <>
            <span className="title with-spacing mb-0">{title}</span>
            {postfix}
          </>
        )}
      </a>
    </NavigationItem>
  );
}

export default NavigationExternalLink;
