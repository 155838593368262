import React from "react";
import { Row, Col, Badge } from "react-bootstrap";

import getStatusClass from "./status";

function CountriesDiff({ countries }) {
  if (countries.any.length === 0) {
    return "There are no countries.";
  }
  return (
    <>
      {countries.removed.length > 0 && (
        <Row>
          <Col sm={6}>
            {countries.removed.map((c) => (
              <CountryBadge key={c} country={c} status="removed" />
            ))}
          </Col>
          <Col sm={6} />
        </Row>
      )}
      {countries.common.length > 0 && (
        <Row>
          <Col sm={12}>
            {countries.common.map((c) => (
              <CountryBadge key={c} country={c} status="no-changes" />
            ))}
          </Col>
        </Row>
      )}
      {countries.added.length > 0 && (
        <Row>
          <Col sm={6} />
          <Col sm={6}>
            {countries.added.map((c) => (
              <CountryBadge key={c} country={c} status="added" />
            ))}
          </Col>
        </Row>
      )}
    </>
  );
}

function CountryBadge({ country, status }) {
  const bg = status === "no-changes" ? "light" : undefined;
  return (
    <span className="h3">
      <Badge pill bg={bg} text="dark" className={getStatusClass(status)}>
        {country}
      </Badge>
    </span>
  );
}

export default CountriesDiff;
