import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React from "react";

function NavigationIcon({ icon, minimized }) {
  return icon ? (
    <div className={classNames("nav__icon icon", { minimized })}>{icon}</div>
  ) : (
    <div className={classNames("nav__icon icon stroked", { minimized })}>
      <FontAwesomeIcon icon={faCircle} />
    </div>
  );
}

export default NavigationIcon;
