import { useField } from "formik";
import PropTypes from "prop-types";
import React from "react";
import BsForm from "react-bootstrap/Form";

import { useStatusValid } from "./error";

const Check = React.forwardRef((props, ref) => {
  const type = props.type !== "switch" ? props.type : "checkbox";
  const [field, meta] = useField({ ...props, type });
  const isStatusValid = useStatusValid(field.name);
  const isInvalid = meta.touched && (!isStatusValid || meta.error);

  return <BsForm.Check ref={ref} {...props} {...field} isInvalid={isInvalid} />;
});

Check.propTypes = {
  type: PropTypes.string,
};

Check.defaultProps = {
  type: "checkbox",
};

export default Check;
