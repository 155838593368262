import * as Yup from "yup";

/* eslint-disable no-template-curly-in-string */
const Schema = Yup.object().shape({
  enabled: Yup.boolean().required("Required!"),
  activeMinimum: Yup.number()
    .integer()
    .required("Required!")
    .min(0, "Should be >= ${min}"),
  activeMaximum: Yup.number()
    .integer()
    .required("Required!")
    .min(1, "Should be >= ${min}"),
  lowestPlacementActive: Yup.boolean().required("Required!"),
  launchAllPlacements: Yup.boolean().required("Required!"),
});
/* eslint-enable no-template-curly-in-string */

export default Schema;
