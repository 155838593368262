import React from "react";
import { Table, Row, Col } from "react-bootstrap";

import Boolean from "~/components/boolean";

import CountryTierView from "../country-tier/view";
import CountryCreateModal from "./create-modal";
import CountryDeleteButton from "./delete-button";
import CountryEditModal from "./edit-modal";

function CountrySettingsTable({ data, reFetch, unitId }) {
  return (
    <>
      <Row className="px-3 my-3">
        <Col className="me-auto">
          <h5 className="">Country settings</h5>
        </Col>
        <Col xs="auto">
          <CountryCreateModal
            existingSettings={data}
            unitId={unitId}
            onCreate={reFetch}
          />
        </Col>
      </Row>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th scope="col">Countries</th>
            <th scope="col">Enabled</th>
            <th scope="col">Launch Target</th>
            <th scope="col">Pause Target</th>
            <th scope="col">Pause Target Low Quarter</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((entry) => (
            <tr key={entry.id}>
              <th scope="row">
                <CountryTierView value={entry.countryTier} />
              </th>
              <td>
                <Boolean value={entry.enabled} />
              </td>
              <td>{entry.launchTarget}</td>
              <td>{entry.pauseTarget}</td>
              <td>{entry.pauseTargetLowQuarter}</td>
              <td>
                <CountryEditModal
                  data={entry}
                  unitId={unitId}
                  onEdit={reFetch}
                />
                <CountryDeleteButton
                  data={entry}
                  unitId={unitId}
                  onDelete={reFetch}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}

export default CountrySettingsTable;
