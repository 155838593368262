import { useField } from "formik";
import React from "react";
import BsForm from "react-bootstrap/Form";

import { ErrorFeedback, useStatusValid } from "./error";

const Control = React.forwardRef((props, ref) => {
  const [field, meta] = useField(props);
  if (field.value == null) {
    field.value = "";
  }
  const isStatusValid = useStatusValid(field.name);
  const isInvalid = meta.touched && (!isStatusValid || meta.error);

  return (
    <BsForm.Control ref={ref} {...props} {...field} isInvalid={isInvalid} />
  );
});

Control.ErrorFeedback = ErrorFeedback;

export default Control;
