import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormikContext } from "formik";
import React from "react";
import { Button, Modal, Row, Col } from "react-bootstrap";
import { Await, useAsyncValue } from "react-router-dom";
import * as Yup from "yup";

import Form from "~/components/form";
import SubmitButton from "~/components/form/submit";
import Spinner from "~/components/spinner";
import { useAlert, axios } from "~/utils";

import "./copy-country-waterfall-button.scss";

const schema = Yup.object().shape({
  source: Yup.string().required("Required!"),
  target: Yup.array().min(1).of(Yup.string()),
  exclude: Yup.array().of(Yup.string()),
});

function CopyCountryWaterfallButton({ unitId, filtersPromise }) {
  const [showModal, setShowModal] = React.useState(false);

  return (
    <>
      <Button
        variant="danger"
        className="mx-2"
        onClick={() => setShowModal(true)}
      >
        <span className="me-2">
          <FontAwesomeIcon icon={faGlobe} />
        </span>{" "}
        Copy Country Waterfall
      </Button>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size="xl"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Copy Country Waterfall</Modal.Title>
        </Modal.Header>
        <React.Suspense
          fallback={<Spinner className="mx-auto" animation="border" />}
        >
          <Await
            resolve={filtersPromise}
            errorElement={<div className="m-2">Could not country data 😔</div>}
          >
            <ModalForm unitId={unitId} onSuccess={() => setShowModal(false)} />
          </Await>
        </React.Suspense>
      </Modal>
    </>
  );
}

function ModalForm({ unitId, onSuccess }) {
  const data = useAsyncValue();
  const addAlert = useAlert();

  const onSubmit = (values) =>
    axios({
      url: `api/ad-units/${unitId}/copy-country-waterfall`,
      method: "POST",
      data: values,
    }).then((r) => {
      r.data.messages.forEach(([variant, message]) => {
        addAlert(message, variant, 0);
      });
      onSuccess();
    });

  const sourceOptions = data.countries.map(({ label }) => ({
    value: label,
    label,
  }));
  const targetOptions = [{ value: "all", label: "ALL" }, ...sourceOptions];

  return (
    <Form
      form={false}
      initialValues={{
        source: sourceOptions[0].value,
        target: [],
        exclude: [],
      }}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      <Modal.Body className="px-5">
        <Form.InnerForm>
          <Form.NonFieldErrors />
          <InputRow label="Source country" name="source">
            <Form.Select name="source" options={sourceOptions} />
          </InputRow>
          <TargetCountriesRow options={targetOptions} />
          <ExcludeCountriesRow options={sourceOptions} />
          <CountryResetter />
        </Form.InnerForm>
      </Modal.Body>
      <Modal.Footer>
        <SubmitModal />
        <SubmitButton title="Copy Country Waterfall" />
      </Modal.Footer>
    </Form>
  );
}

function SubmitModal() {
  const { isSubmitting } = useFormikContext();
  return (
    isSubmitting && (
      <Modal
        animation={false}
        centered
        className="submit-spinner-modal"
        fullscreen
        show
        size="xl"
      >
        <Spinner />
      </Modal>
    )
  );
}

function TargetCountriesRow({ options }) {
  const formikContext = useFormikContext();
  const isOptionDisabled = (option, selected) => {
    if (option.value === "all") {
      return selected.some((v) => v.value !== "all");
    }
    return (
      option.value === formikContext.values.source ||
      selected.some((v) => v.value === "all")
    );
  };

  return (
    <InputRow label="Target countries" name="target">
      <Form.Select
        isMulti
        name="target"
        options={options}
        isOptionDisabled={isOptionDisabled}
        onPaste={(event) =>
          handlePaste(event, formikContext, "target", options, isOptionDisabled)
        }
      />
    </InputRow>
  );
}

function ExcludeCountriesRow({ options }) {
  const formikContext = useFormikContext();
  const { values, setFieldValue } = formikContext;
  const shouldShowExclude = values.target.some((v) => v === "all");
  React.useEffect(() => {
    if (values.exclude.length > 0 && !shouldShowExclude) {
      setFieldValue("exclude", []);
    }
  }, [shouldShowExclude, setFieldValue, values.exclude.length]);

  if (!shouldShowExclude) return null;

  const isOptionDisabled = (option) => option.value === values.source;

  return (
    <InputRow label="Excluding" name="exclude">
      <Form.Select
        isMulti
        name="exclude"
        options={options}
        isOptionDisabled={isOptionDisabled}
        onPaste={(event) =>
          handlePaste(
            event,
            formikContext,
            "exclude",
            options,
            isOptionDisabled
          )
        }
      />
    </InputRow>
  );
}

function handlePaste(event, formikContext, field, options, isOptionDisabled) {
  event.nativeEvent.preventDefault();
  const pastedText = event.clipboardData.getData("Text");

  const labelsToAdd = new Set();
  pastedText
    .split(",")
    .map((v) => v.trim())
    .forEach((v) => labelsToAdd.add(v));

  const currentSelection = formikContext.values[field];
  const currentValues = new Set();
  currentSelection.forEach((v) => currentValues.add(v));

  const extraSelection = options
    .filter((o) => !isOptionDisabled(o, currentSelection))
    .filter((opt) => !currentValues.has(opt.value))
    .filter((opt) => labelsToAdd.has(opt.label))
    .map((opt) => opt.value);
  formikContext.setFieldValue(field, [...currentSelection, ...extraSelection]);
}

function CountryResetter() {
  const { values, setFieldValue } = useFormikContext();
  const { source, target, exclude } = values;
  React.useEffect(() => {
    if (exclude.some((v) => v === source)) {
      setFieldValue(
        "exclude",
        exclude.filter((v) => v !== source)
      );
    }
    if (target.some((v) => v === source)) {
      setFieldValue(
        "target",
        target.filter((v) => v !== source)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [source, setFieldValue]);
  return null;
}

function InputRow({ label, name, children }) {
  return (
    <Form.Group as={Row} className="mb-3" controlId={name}>
      <Form.Label column sm={4}>
        {label}
      </Form.Label>
      <Col sm={8} className="d-flex flex-wrap align-items-center">
        <div className="w-100">{children}</div>
        <Form.Control.ErrorFeedback name={name} />
      </Col>
    </Form.Group>
  );
}

export default CopyCountryWaterfallButton;
