import React from "react";
import { RouterProvider } from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/scss/bootstrap.scss";

import Spinner from "~/components/spinner";
import router from "~/routes";

import { AlertProvider } from "./alerts";
import { AuthProvider } from "./auth";
import { ConfirmProvider } from "./confirmation";

const layers = [React.StrictMode, AuthProvider, AlertProvider, ConfirmProvider];

const core = <RouterProvider router={router} fallbackElement={<Spinner />} />;

const onion = layers
  .reverse()
  .reduce((children, Layer) => <Layer>{children}</Layer>, core);

function App() {
  return onion;
}

export default App;
