import React from "react";
import { Col, Row } from "react-bootstrap";

import ActiveIndicator from "~/components/active-indicator";
import Form from "~/components/form";
import SubmitButton from "~/components/form/submit";
import { axios } from "~/utils";

import AdUnitEditValidation from "./edit-form-validation";

function AdUnitEditSectionForm({ adUnit, optionsData, onEdit }) {
  return (
    <AdUnitEditSectionFormContent
      options={optionsData}
      adUnit={adUnit}
      onEdit={onEdit}
    />
  );
}

function AdUnitEditSectionFormContent({ options, adUnit, onEdit }) {
  const { ...initial } = adUnit;
  const formatOptions = options.formats.filter((f) => f.value !== "");
  const appOptions = options.apps.filter((f) => f.value !== "");
  const platformOptions = options.platforms.filter((p) => p.value !== "");
  return (
    <div className="p-3 pb-1">
      <Form
        initialValues={initial}
        validationSchema={AdUnitEditValidation}
        onSubmit={(values) =>
          axios({
            method: "PUT",
            url: `api/ad-units/${adUnit.id}`,
            data: values,
          }).then(() => {
            onEdit();
          })
        }
      >
        <Row className="mb-3">
          <Col>
            <Row>
              <Col md={2} style={{ textAlign: "end", alignSelf: "center" }}>
                <Form.Label direction="vertical">Name</Form.Label>
              </Col>
              <Col>
                <Form.Control type="text" name="name" disabled />
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col md={3} style={{ textAlign: "end", alignSelf: "center" }}>
                <Form.Label direction="vertical" style={{ textAlign: "right" }}>
                  App
                </Form.Label>
              </Col>
              <Col>
                <Form.Select
                  className="w-100"
                  options={appOptions}
                  name="app"
                />
              </Col>
              <Form.Control.ErrorFeedback name="app" />
            </Row>
          </Col>
          <Col className="ps-4">
            <Row>
              <Col md={3} style={{ textAlign: "end", alignSelf: "center" }}>
                <Form.Label className="mb-0" direction="vertical">
                  Push as test
                </Form.Label>
              </Col>
              <Col>
                <Form.Check name="pushAsTest" />
              </Col>
              <Form.Control.ErrorFeedback name="pushAsTest" />
            </Row>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Row>
              <Col md={2} style={{ textAlign: "end", alignSelf: "center" }}>
                <Form.Label direction="vertical">MAX ID</Form.Label>
              </Col>
              <Col>
                <Form.Control type="text" name="maxId" disabled />
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col md={3} style={{ textAlign: "end", alignSelf: "center" }}>
                <Form.Label direction="vertical" style={{ textAlign: "right" }}>
                  Platform
                </Form.Label>
              </Col>
              <Col>
                <Form.Select
                  className="w-100"
                  options={platformOptions}
                  name="platform"
                />
              </Col>
              <Form.Control.ErrorFeedback name="platform" />
            </Row>
          </Col>
          <Col className="ps-4">
            <Row>
              <Col md={3} style={{ textAlign: "end", alignSelf: "center" }}>
                <Form.Label className="mb-0" direction="vertical">
                  Include in run
                </Form.Label>
              </Col>
              <Col>
                <Form.Check name="includeInRun" />
              </Col>
              <Form.Control.ErrorFeedback name="includeInRun" />
            </Row>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Row>
              <Col md={2} style={{ textAlign: "end", alignSelf: "center" }}>
                <Form.Label direction="vertical">Ad format</Form.Label>
              </Col>
              <Col>
                <Form.Select
                  className="w-100"
                  options={formatOptions}
                  name="adFormat"
                />
              </Col>
              <Form.Control.ErrorFeedback name="adFormat" />
            </Row>
          </Col>
          <Col>
            <Row>
              <Col md={3} style={{ textAlign: "end", alignSelf: "center" }}>
                <Form.Label className="mb-0" direction="vertical">
                  Is LAT
                </Form.Label>
              </Col>
              <Col>
                <Form.Check name="isLat" />
              </Col>
              <Form.Control.ErrorFeedback name="isLat" />
            </Row>
          </Col>
          <Col className="ps-4">
            <Row>
              <Col md={3} style={{ textAlign: "end", alignSelf: "center" }}>
                <Form.Label className="mb-0" direction="vertical">
                  Debug
                </Form.Label>
              </Col>
              <Col>
                <Form.Check name="debug" />
              </Col>
              <Form.Control.ErrorFeedback name="debug" />
            </Row>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={4}>
            <Row>
              <Col md={2} style={{ textAlign: "end", alignSelf: "center" }}>
                <Form.Label direction="vertical">Max status</Form.Label>
              </Col>
              <Col>
                <ActiveIndicator active={adUnit.maxStatus} />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-end">
            <SubmitButton />
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default AdUnitEditSectionForm;
