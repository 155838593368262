import React from "react";
import { useLoaderData, useRevalidator, useParams } from "react-router-dom";

import PageHeader from "~/components/page-header";
import { axios, cachedAxios } from "~/utils";

import AdUnitInfoSection from "./ad-unit-info-section";
import CountrySettingsTable from "./country";
import NetworkSettingsTable from "./network";
import { OptionsProvider, transformOptions } from "./options";
import AdUnitScheduler from "./scheduler";

function AdUnitSettingsPage() {
  const { adUnitID, scheduleID } = useParams();
  const { info, countries, networks, filterOptions } = useLoaderData();
  const revalidator = useRevalidator();

  return (
    <OptionsProvider value={filterOptions}>
      <PageHeader />
      <AdUnitInfoSection
        data={info}
        reFetch={revalidator.revalidate}
        settingsId={scheduleID}
      />
      <hr />
      <CountrySettingsTable
        data={countries}
        reFetch={revalidator.revalidate}
        unitId={adUnitID}
      />
      <hr />
      <NetworkSettingsTable
        data={networks}
        reFetch={revalidator.revalidate}
        unitId={adUnitID}
      />
      <hr />
      <AdUnitScheduler settingsId={scheduleID} />
    </OptionsProvider>
  );
}

AdUnitSettingsPage.loader = async function loader({ params }) {
  const { adUnitID, scheduleID } = params;

  const [infoResponse, countriesResponse, networksResponse, filtersResponse] =
    await Promise.all([
      axios(`api/unit-settings/${scheduleID}`),
      axios(`api/ad-units/${adUnitID}/country-params`),
      axios(`api/ad-units/${adUnitID}/network-params`),
      cachedAxios("api/settings-filters"),
    ]);
  const filterOptions = transformOptions(filtersResponse.data);
  return {
    info: infoResponse.data,
    countries: countriesResponse.data,
    networks: networksResponse.data,
    filterOptions,
  };
};

export default AdUnitSettingsPage;
