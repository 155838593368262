import React from "react";

import Form from "~/components/form";

import { useOptions } from "../options";

function CountryTierSelect({ name, ...rest }) {
  const options = useOptions();

  const isOptionDisabled = React.useCallback((option, selected) => {
    const isAllSelected = selected.some((o) => o.value === "all");
    const isTierSelected = selected.some((o) => typeof o.value === "number");
    const isCountrySelected = selected.some((o) => typeof o.value === "string");
    const isCountryOption =
      typeof option.value === "string" && option.value !== "all";
    return (
      isAllSelected || isTierSelected || (isCountrySelected && !isCountryOption)
    );
  }, []);

  return (
    <Form.Select
      isMulti
      isOptionDisabled={isOptionDisabled}
      closeMenuOnSelect={false}
      options={options.countries}
      name={name}
      {...rest}
    />
  );
}

export default CountryTierSelect;
