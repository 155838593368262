import { useField } from "formik";
import React from "react";
import { WeekdaysInput } from "react-weekdays-input";

function WeekdayPicker({ name, ...props }) {
  const [{ value }, , { setValue }] = useField(name);
  return (
    <WeekdaysInput {...props} value={value || "0000000"} onChange={setValue} />
  );
}
export default WeekdayPicker;
