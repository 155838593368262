import React from "react";
import { Col, Modal, Row } from "react-bootstrap";

import Form from "~/components/form";
import SubmitButton from "~/components/form/submit";
import IconButton from "~/components/icon-button";
import { axios } from "~/utils";

import CountryTierView from "../country-tier/view";
import Schema from "./validation-schema";

function CountryEditModal({ data, unitId, onEdit }) {
  const { countryTier, id, ...initial } = data;
  const [show, setShow] = React.useState(false);

  const closeModal = () => setShow(false);
  const showModal = () => setShow(true);
  return (
    <>
      <IconButton.Edit size="sm" onClick={showModal} />
      <Modal show={show} onHide={closeModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            Edit setting for country{" "}
            <i>
              <CountryTierView value={countryTier} />
            </i>
          </Modal.Title>
        </Modal.Header>
        <Form
          form={false}
          initialValues={initial}
          validationSchema={Schema}
          onSubmit={(values) =>
            axios({
              method: "PUT",
              url: `api/ad-units/${unitId}/country-params/${id}`,
              data: values,
            }).then(() => {
              onEdit();
              closeModal();
            })
          }
        >
          <Modal.Body className="px-5">
            <Form.InnerForm>
              <Form.Group as={Row} className="mb-3" controlId="country-enabled">
                <Form.Label column sm={4}>
                  Enabled
                </Form.Label>
                <Col sm={8}>
                  <Form.Check name="enabled" />
                  <Form.Control.ErrorFeedback name="enabled" />
                </Col>
              </Form.Group>

              <Form.Group
                as={Row}
                className="mb-3"
                controlId="country-launch-target"
              >
                <Form.Label column sm={4}>
                  Launch target
                </Form.Label>
                <Col sm={8}>
                  <Form.Control type="number" name="launchTarget" />
                  <Form.Control.ErrorFeedback name="launchTarget" />
                </Col>
              </Form.Group>

              <Form.Group
                as={Row}
                className="mb-3"
                controlId="country-pause-target"
              >
                <Form.Label column sm={4}>
                  Pause target
                </Form.Label>
                <Col sm={8}>
                  <Form.Control type="number" name="pauseTarget" />
                  <Form.Control.ErrorFeedback name="pauseTarget" />
                </Col>
              </Form.Group>

              <Form.Group
                as={Row}
                className="mb-3"
                controlId="country-pause-target-low-quarter"
              >
                <Form.Label column sm={4}>
                  Pause target low quarter
                </Form.Label>
                <Col sm={8}>
                  <Form.Control type="number" name="pauseTargetLowQuarter" />
                  <Form.Control.ErrorFeedback name="pauseTargetLowQuarter" />
                </Col>
              </Form.Group>
              {/* this input is needed to allow to submit form by pressing enter */}
              <input type="submit" className="d-none" />
            </Form.InnerForm>
          </Modal.Body>
          <Modal.Footer>
            <SubmitButton />
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default CountryEditModal;
