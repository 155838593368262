const defaultAlertTimeout = 5000;

/* eslint-disable no-underscore-dangle */
const environmentConfig =
  window && window._environmentConfig ? window._environmentConfig : {};
/* eslint-enable no-underscore-dangle */

function getEnvironmentName() {
  const shortName = environmentConfig.environmentShortName || "UNKN";
  const longName = environmentConfig.environmentName || "UNKNOWN";
  return [shortName, longName];
}

function getBaseURL() {
  const baseURL = environmentConfig.baseURL || "/tlc-waterfalls";
  return baseURL;
}

const admobCredentials = {
  client_id: environmentConfig.admobClientId,
  scope: "https://www.googleapis.com/auth/admob.readonly",
};

const isExternalDashboard = environmentConfig.isExternalDashboard || false;
const supportEmail =
  environmentConfig.supportEmail || "arc@tripledotstudios.com";

export {
  getEnvironmentName,
  defaultAlertTimeout,
  admobCredentials,
  getBaseURL,
  isExternalDashboard,
  supportEmail,
};
