import { faPen, faTrash, faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button } from "react-bootstrap";

import "./icon-button.scss";

function IconButton({ icon, title, ...rest }) {
  return (
    <Button
      title={title}
      variant="light"
      {...rest}
      className="icon-button rounded-circle border-0"
    >
      <FontAwesomeIcon icon={icon} />
    </Button>
  );
}

function IconButtonEdit({ title = "Edit", ...rest }) {
  return <IconButton title={title} icon={faPen} {...rest} />;
}

function IconButtonDelete({ title = "Delete", ...rest }) {
  return <IconButton title={title} icon={faTrash} {...rest} />;
}

function IconButtonDuplicate({ title = "Duplicate", ...rest }) {
  return <IconButton title={title} icon={faCopy} {...rest} />;
}

IconButton.Edit = IconButtonEdit;
IconButton.Delete = IconButtonDelete;
IconButton.Duplicate = IconButtonDuplicate;

export default IconButton;
