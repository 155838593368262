import React from "react";
import { useRouteError } from "react-router-dom";

function NotFoundPage() {
  const error = useRouteError();
  React.useEffect(() => {
    if (error) {
      /* eslint-disable no-console */
      console.error(error);
      /* eslint-enable no-console */
    }
  }, [error]);
  return "Snap! Something went wrong! 😔";
}

export default NotFoundPage;
