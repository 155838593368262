import { useField, useFormikContext } from "formik";
import React from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { useLoaderData } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";

import { AdmobCredsGuard } from "~/blocks/admob/credentials";
import {
  Cpm,
  getNumberFormatter,
  renderLabel,
} from "~/blocks/admob/placement-format";
import Form from "~/components/form";
import { dateToString } from "~/components/form/date-picker";
import { DetachedSelect as Select } from "~/components/form/select";
import SubmitButton from "~/components/form/submit";
import IconButton from "~/components/icon-button";
import PageHeader from "~/components/page-header";
import Spinner from "~/components/spinner";
import { axios, useAxios, useAxiosPolling, useConfirmation } from "~/utils";

function AdMobRefreshPage() {
  const data = useLoaderData();
  const options = React.useMemo(() => buildAppOptions(data), [data]);
  const refreshRef = React.useRef(() => {});
  const submitSwapRef = React.useRef(() => {});
  const [maxId, setMaxId] = React.useState("");
  const currentDate = new Date();
  const [referenceDate, setReferenceDate] = React.useState(currentDate);
  const unitId = useUnitId(maxId, data);
  const [placementData, setPlacementData] = React.useState([]);
  const placementDataRef = React.useCallback(
    (refData) => {
      setPlacementData(refData);
    },
    [setPlacementData]
  );
  return (
    <>
      <PageHeader />
      <Container>
        <AdmobCredsGuard>
          {(token) => (
            <>
              <Row className="my-5 d-flex align-items-center">
                <Col xs="auto">Date:</Col>
                <Col xs={2}>
                  <ReactDatePicker
                    className="form-control"
                    minDate={currentDate}
                    selected={referenceDate}
                    dateFormat="dd/MM/yyyy"
                    onChange={(date) => setReferenceDate(date)}
                  />
                </Col>
                <Col xs="auto">Ad unit: </Col>
                <Col xs={4}>
                  <Select value={maxId} onChange={setMaxId} options={options} />
                </Col>
                <Col xs="auto">
                  <Button
                    variant="outline-primary"
                    onClick={() => refreshRef.current()}
                  >
                    Find matches
                  </Button>
                </Col>
                {!!maxId && (
                  <Col xs="auto">
                    <Button
                      variant="success"
                      onClick={() => {
                        submitSwapRef.current();
                      }}
                    >
                      Swap placements
                    </Button>
                  </Col>
                )}
                {placementData.length > 0 && (
                  <Col xs="auto">
                    <AdMobPlacementCreationModal
                      className="ms-2"
                      placementsData={placementData}
                      refreshRef={refreshRef}
                      unitId={unitId}
                      maxId={maxId}
                      token={token}
                      creationDate={referenceDate}
                    />
                  </Col>
                )}
              </Row>
              {!!maxId && (
                <PlacementRefresher
                  maxId={maxId}
                  referenceDate={referenceDate}
                  unitId={unitId}
                  token={token}
                  refreshRef={refreshRef}
                  submitRef={submitSwapRef}
                  placementsDataRef={placementDataRef}
                />
              )}
            </>
          )}
        </AdmobCredsGuard>
      </Container>
    </>
  );
}

AdMobRefreshPage.loader = async function loader() {
  const response = await axios("api/ad-units?page=all&active=1");
  return response.data;
};

function useUnitId(maxId, data) {
  const idByMax = React.useMemo(
    () =>
      data ? Object.fromEntries(data.results.map((e) => [e.maxId, e.id])) : {},
    [data]
  );
  return maxId ? idByMax[maxId] : null;
}

function SubmitRefPropagator({ submitRef }) {
  const { handleSubmit } = useFormikContext();
  React.useEffect(() => {
    submitRef.current = handleSubmit;
  }, [submitRef, handleSubmit]);
}

function PlacementRefresher({
  maxId,
  referenceDate,
  unitId,
  token,
  refreshRef,
  submitRef,
  placementsDataRef,
}) {
  // refresh_admob_placement permission is checked both for create and refresh actions for legacy reasons
  const [{ data, loading, error }, reFetch] = useAxios({
    url: "api/refresh-admob-placements",
    method: "POST",
    headers: { "X-Admob-Token": token },
    data: {
      maxIds: [maxId],
      referenceDate: dateToString(referenceDate),
    },
  });
  const unitData = data && data[0].replacements;
  React.useEffect(() => {
    const prev = refreshRef.current;
    refreshRef.current = reFetch;
    return () => {
      refreshRef.current = prev;
    };
  }, [reFetch, refreshRef, submitRef]);
  const confirmReplace = useConfirmation(
    <p>
      There are some placements that don&apos;t have a new placement and they
      will not be replaced. Do you want to continue?
    </p>
  );
  const numberFormatter = React.useMemo(
    () => getNumberFormatter(unitData),
    [unitData]
  );
  const [messages, setMessages] = React.useState([]);
  const isModalShown = !!messages.length;
  const closeModal = () => setMessages([]);
  const commit = (replacements) =>
    axios({
      url: "api/refresh-admob-placements",
      method: "PATCH",
      headers: { "X-Admob-Token": token },
      data: {
        maxId,
        unitId,
        replacements,
        targetDate: dateToString(referenceDate),
      },
    })
      .then((r) => {
        setMessages(r.data.messages);
      })
      .catch(() => {
        setMessages([["error", "Error while replacing the placements."]]);
      });
  if (loading) return <Spinner />;
  if (error) return "Snap! Some error has happened!";
  placementsDataRef(unitData);
  return (
    <Form
      initialValues={getInitial(unitData)}
      onSubmit={(values) => {
        const [replacements, hasEmptyValues] = prepareForSubmission(
          unitData,
          values
        );
        return hasEmptyValues
          ? confirmReplace(() => commit(replacements))
          : commit(replacements);
      }}
    >
      <SubmitRefPropagator submitRef={submitRef} />
      <Row>
        <Table bordered size="sm" style={{ verticalAlign: "middle" }}>
          <thead>
            <tr>
              <th scope="col">Group</th>
              <th scope="col">MAX placement</th>
              <th scope="col">AdMob placement</th>
              <th scope="col" aria-label="Delete" />
            </tr>
          </thead>
          <tbody>
            {unitData.map((entry) => (
              <PlacementEdit
                key={getKey(entry)}
                value={entry}
                numberFormatter={numberFormatter}
              />
            ))}
          </tbody>
        </Table>
      </Row>
      <Modal backdrop="static" show={isModalShown} onHide={closeModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Admob Refresh</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {messages.map(([variant, message, response]) => (
            <Alert
              key={message}
              variant={variant === "error" ? "danger" : variant}
            >
              {message}
              {!!response && (
                <>
                  <hr />
                  {response}
                </>
              )}
            </Alert>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Form>
  );
}

function PlacementEdit({ value, numberFormatter }) {
  const [{ value: isActive }, , { setValue }] = useField(
    `active-${getKey(value)}`
  );
  const isEmpty = useField(`val-${getKey(value)}`)[0].value === null;
  const styles = {
    control: (provided) => {
      const extra = isEmpty ? { borderColor: "#dc3545" } : {};
      return {
        ...provided,
        ...extra,
      };
    },
  };

  const options = React.useMemo(
    () => buildPlacementOptions(value, numberFormatter),
    [value, numberFormatter]
  );
  if (!isActive) return null;
  return (
    <tr>
      <td>{value.group}</td>
      <td className="font-monospace">
        {value.id} / <Cpm value={value.cpm} formatter={numberFormatter} />
      </td>
      <td className="font-monospace">
        <Form.Select
          styles={styles}
          options={options}
          formatOptionLabel={(v) => v.renderedLabel}
          name={`val-${getKey(value)}`}
        />
      </td>
      <td>
        <IconButton.Delete onClick={() => setValue(false)} />
      </td>
    </tr>
  );
}

function getInitial(data) {
  const vals = Object.fromEntries(
    data.map((e) => [`val-${getKey(e)}`, e.optimal])
  );
  const actives = Object.fromEntries(
    data.map((e) => [`active-${getKey(e)}`, true])
  );
  return { ...vals, ...actives };
}

function prepareForSubmission(placements, formData) {
  const replacements = [];
  let hasEmptyValues = false;
  placements.forEach((v) => {
    const key = getKey(v);
    if (!formData[`active-${key}`]) return;
    const previous = v.id;
    const previousCpm = v.cpm;
    const next = formData[`val-${key}`];
    hasEmptyValues = hasEmptyValues || next === null;
    if (previous === next || next === null) return;
    const nextData = v.choices.find((e) => e.id === next);
    const nextCpm = nextData.cpm;
    const nextName = nextData.name;
    replacements.push({
      group: v.group,
      previous,
      previousCpm,
      next,
      nextCpm,
      nextName,
    });
  });
  return [replacements, hasEmptyValues];
}

function getKey(entry) {
  return `${entry.group}-${entry.id}`;
}

function buildPlacementOptions(value, numberFormatter) {
  return value.choices.map((v) => ({
    value: v.id,
    label: `${v.name} / $${v.cpm} / ${v.id}`,
    renderedLabel: renderLabel(v, numberFormatter),
  }));
}

function buildAppOptions(data) {
  if (!data) return [];
  const options = data.results.map((v) => ({
    value: v.maxId,
    label: `${v.name} (${v.maxId}) [${v.id}]`,
  }));
  return [{ value: "", label: "" }, ...options];
}

/* eslint-disable no-template-curly-in-string */
const credentialsValidationSchema = Yup.object().shape({
  login: Yup.string().required("Required!"),
  password: Yup.string().required("Required!"),
});
/* eslint-enable no-template-curly-in-string */

function CreationLoginWait() {
  const [{ data, error }] = useAxiosPolling(2000, "api/magic-number-2fa");

  return (
    <Modal.Body className="px-5">
      <>
        <Row>Waiting 90 seconds for 2FA to be accepted</Row>
        {!!data && !error && (
          <Row>
            The 2FA Magic number shown for prompt is:{" "}
            <strong>{data.magicNumber}</strong>
          </Row>
        )}
      </>
    </Modal.Body>
  );
}

function AdMobPlacementCreationModal({
  placementsData,
  refreshRef,
  unitId,
  maxId,
  token,
  creationDate,
}) {
  const [show, setShow] = React.useState(false);
  const [modalState, setModalState] = React.useState("login");

  const sendCredentials = useAxios(
    {
      method: "POST",
      url: `api/create-admob-placements/${placementsData[0].admobAppId}`,
      headers: { "X-Admob-Token": token },
    },
    { manual: true }
  )[1];

  let spinnerTimeout = null;

  const closeModal = () => {
    setShow(false);
    setModalState("login");
  };
  const showModal = () => setShow(true);
  const showTimer = () => {
    setModalState("timer");
  };
  const showSpinner = () => {
    setModalState("spinner");
  };
  return (
    <>
      <Button variant="info" onClick={showModal}>
        Create placements
      </Button>
      <Modal show={show} onHide={closeModal} backdrop="static" centered>
        <Modal.Header closeButton>
          <Modal.Title>Create AdMob placements</Modal.Title>
        </Modal.Header>
        {modalState === "timer" && <CreationLoginWait />}
        {modalState === "error" && (
          <Modal.Body className="px-5">
            <Row>Snap! Some error has happened!</Row>
          </Modal.Body>
        )}
        {modalState === "spinner" && (
          <Modal.Body className="px-5">
            <Spinner />
          </Modal.Body>
        )}
        {modalState === "noCreate" && (
          <Modal.Body className="px-5">
            <Row>No placements should be created</Row>
          </Modal.Body>
        )}
        {modalState === "login" && (
          <Form
            form={false}
            initialValues={{
              login: "",
              password: "",
            }}
            validationSchema={credentialsValidationSchema}
            onSubmit={(values) => {
              sendCredentials({
                data: {
                  unitId,
                  maxId,
                  login: values.login,
                  password: values.password,
                  targetDate: dateToString(creationDate),
                  idempotencyKey: uuidv4(),
                },
              })
                .then(() => {
                  closeModal();
                  refreshRef.current();
                })
                .catch(() => {
                  setModalState("error");
                  clearTimeout(spinnerTimeout);
                });
              showTimer();
              spinnerTimeout = setTimeout(showSpinner, 90000);
            }}
          >
            <Modal.Body className="px-5">
              <Row className="mb-4">
                <Col>Input Google Account credentials used to access AdMob</Col>
              </Row>
              <Form.InnerForm>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="country-enabled"
                >
                  <Form.Label column sm={4}>
                    Login
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control name="login" />
                    <Form.Control.ErrorFeedback name="login" />
                  </Col>
                </Form.Group>

                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="country-launch-target"
                >
                  <Form.Label column sm={4}>
                    Password
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control type="password" name="password" />
                    <Form.Control.ErrorFeedback name="password" />
                  </Col>
                </Form.Group>

                {/* this input is needed to allow to submit form by pressing enter */}
                <input type="submit" className="d-none" />
              </Form.InnerForm>
            </Modal.Body>
            <Modal.Footer>
              <SubmitButton title="Start creation" />
            </Modal.Footer>
          </Form>
        )}
      </Modal>
    </>
  );
}

export default AdMobRefreshPage;
