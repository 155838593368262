import { faRectangleAd, faScroll } from "@fortawesome/free-solid-svg-icons";
import { createBrowserRouter } from "react-router-dom";

import Layout from "~/base/layout";

import {
  HomePage,
  AdmobLogsPage,
  AdMobRoot,
  AdUnitsPage,
  AdUnitConfigPage,
  AdUnitSettingsPage,
  CountryWaterfallsPage,
  NotFoundPage,
  VersionsPage,
  ErrorPage,
  LogoutPage,
  AuthErrorPage,
  AuthCallbackPage,
} from "./pages";

// handle.navbar.color should be in #ffffff format
const config = [
  {
    path: "logout",
    element: <LogoutPage />,
  },
  {
    path: "auth/sso/callback",
    loader: AuthCallbackPage.loader,
    element: <AuthCallbackPage />,
  },
  {
    path: "auth/error",
    element: <AuthErrorPage />,
  },
  {
    path: "/*",
    loader: Layout.loader,
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        errorElement: <ErrorPage />,
        children: [
          {
            index: true,
            element: <HomePage />,
            handle: {
              title: "TLC",
            },
          },
          {
            path: "ad-units",
            handle: {
              crumb: "Ad units",
              title: "TLC: Ad units",
              requiredPermissions: "tlc_operations:waterfall_optimization:view",
              navbar: {
                title: "Ad Units",
                icon: faRectangleAd,
                color: "#8649e2",
              },
            },
            children: [
              {
                index: true,
                loader: AdUnitsPage.loader,
                element: <AdUnitsPage />,
              },
              {
                path: ":adUnitID",
                handle: {
                  crumb: (crumbData) => crumbData.adUnitName || "Ad unit",
                },
                children: [
                  {
                    index: true,
                    loader: AdUnitConfigPage.loader,
                    element: <AdUnitConfigPage />,
                    handle: {
                      getCrumbData: (data) => ({
                        adUnitName: data.adUnit.name,
                      }),
                      title: (data) => `TLC: Ad unit ${data.adUnit.name}`,
                    },
                  },
                  {
                    path: "waterfalls",
                    loader: CountryWaterfallsPage.loader,
                    element: <CountryWaterfallsPage />,
                    handle: {
                      getCrumbData: (data) => ({ adUnitName: data.name }),
                      crumb: "Country waterfalls",
                      title: (data) =>
                        `TLC: Ad unit ${data.name} country waterfall`,
                    },
                  },
                  {
                    path: "versions",
                    loader: VersionsPage.loader,
                    element: <VersionsPage />,
                    handle: {
                      getCrumbData: (data) => ({
                        adUnitName: data.adUnit.name,
                      }),
                      crumb: "Versions comparer",
                      title: (data) =>
                        `TLC: Ad unit ${data.adUnit.name} versions`,
                    },
                  },
                  {
                    path: "schedules/:scheduleID/edit",
                    loader: AdUnitSettingsPage.loader,
                    element: <AdUnitSettingsPage />,
                    handle: {
                      getCrumbData: (data) => ({
                        adUnitName: data.info.adUnit,
                      }),
                      crumb: "Configuration",
                      title: (data) =>
                        `TLC: Ad unit ${data.info.adUnit} configuration`,
                    },
                  },
                ],
              },
            ],
          },
          {
            path: "admob",
            element: <AdMobRoot />,
            handle: {
              navbar: {
                section: true,
                title: "Ad Mob",
              },
              requiredPermissions: "tlc_operations:admob_operations:view",
            },
            children: [
              {
                path: "logs",
                loader: AdmobLogsPage.loader,
                element: <AdmobLogsPage />,
                handle: {
                  crumb: "Change History",
                  title: "TLC: AdMob Change History",
                  navbar: {
                    title: "Change History",
                    icon: faScroll,
                  },
                },
              },
            ],
          },
          {
            path: "*",
            element: <NotFoundPage />,
            handle: {
              crumb: "Page not found",
              title: "TLC: page not found",
            },
          },
        ],
      },
    ],
  },
];

const router = createBrowserRouter(config);

export default router;
