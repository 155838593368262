import React from "react";
import { Button } from "react-bootstrap";

import Spinner from "~/components/spinner";
import { admobCredentials } from "~/constants";
import { loadScript } from "~/utils";

// 50 minutes, token is valid for 60 minutes
const tokenLifeTime = 1000 * 60 * 50;

function AdmobCredsContainer({ children }) {
  const [loading, setLoading] = React.useState(true);
  const [client, setClient] = React.useState(null);
  const [token, setToken] = React.useState(null);
  const context = React.useMemo(
    () => ({ loading, client, token }),
    [loading, client, token]
  );
  React.useEffect(() => {
    if (!token) return () => {};
    const timeout = setTimeout(() => {
      setToken(null);
    }, tokenLifeTime);
    return () => {
      clearTimeout(timeout);
    };
  }, [token]);

  React.useEffect(() => {
    loadScript("https://accounts.google.com/gsi/client").then(() => {
      setClient(
        window.google.accounts.oauth2.initTokenClient({
          ...admobCredentials,
          callback: (response) => {
            setToken(response.access_token);
          },
        })
      );
      setLoading(false);
    });
  }, []);

  return (
    <admobCredsContext.Provider value={context}>
      {children}
    </admobCredsContext.Provider>
  );
}

function AdmobCredsGuard({ children }) {
  const { loading, client, token } = React.useContext(admobCredsContext);

  if (loading) return <Spinner />;
  if (!token)
    return (
      <div className="d-flex justify-content-center mt-5">
        <Button onClick={() => client.requestAccessToken()}>
          Authenticate
        </Button>
      </div>
    );
  if (typeof children === "function") {
    return children(token);
  }
  return (
    <tokenContext.Provider value={token}>{children}</tokenContext.Provider>
  );
}

function useAdmobToken() {
  return React.useContext(tokenContext);
}

const admobCredsContext = React.createContext();
const tokenContext = React.createContext();

export { AdmobCredsContainer, AdmobCredsGuard, useAdmobToken };
