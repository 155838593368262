import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormikContext } from "formik";
import React from "react";
import { Col, Button } from "react-bootstrap";

import Label from "~/components/form/label";

function FilterCol({ children, label, md = 2, xs = 5 }) {
  return (
    <Col md={md} xs={xs}>
      <Label direction="vertical" text={label}>
        {children}
      </Label>
    </Col>
  );
}

function ResetButton({ resetState = null }) {
  const { resetForm, handleSubmit } = useFormikContext();
  const clearFilters = () => {
    if (resetState) {
      // explicit way to resetForm without setting callback in formik context,
      // as putting reset callback into formik leads to calling reset function on submit
      // which is not always a desired behaviour
      resetState();
    } else {
      resetForm();
      handleSubmit();
    }
  };
  return (
    <Button variant="outline-danger" onClick={clearFilters}>
      <span className="me-2">
        <FontAwesomeIcon icon={faCircleXmark} />
      </span>
      Reset Filters
    </Button>
  );
}

export { ResetButton, FilterCol };
