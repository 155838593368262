import React, { useState } from "react";
import { Accordion, Button, Modal } from "react-bootstrap";
import { useLoaderData, defer } from "react-router-dom";

import {
  OperationIndicator,
  StatusIndicator,
  UnitsAccordion,
} from "~/blocks/admob/log";
import {
  PageFiltersForm,
  filtersLoader,
  TotalResultsIndicator,
} from "~/blocks/admob/log-filters";
import PageHeader from "~/components/page-header";
import Pagination from "~/components/pagination";
import SimpleAwaiter from "~/components/simple-awaiter";
import { axios, extractSearch } from "~/utils";

function AdmobLogsPage() {
  const { logs: data, filtersPromise } = useLoaderData();
  return (
    <>
      <PageHeader
        filter={
          <SimpleAwaiter promise={filtersPromise}>
            {(filters) => <PageFiltersForm options={filters} />}
          </SimpleAwaiter>
        }
        filterOnEnd
      >
        <TotalResultsIndicator total={data.count} />
      </PageHeader>
      {data.count === 0 ? (
        <h5 className="mt-4 text-center">No Change History logs found</h5>
      ) : (
        <>
          <BulkOperationAccordion operations={data.results} />
          <Pagination data={data} />
        </>
      )}
    </>
  );
}

AdmobLogsPage.loader = async function loader({ request }) {
  const search = extractSearch(request.url);
  const response = await axios(`api/admob-logs${search}`);
  const filtersPromise = filtersLoader();
  return defer({
    logs: response.data,
    filtersPromise,
  });
};

function BulkOperationAccordion({ operations }) {
  const [screenshotOpen, openScreenshot] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  return (
    <Accordion className="my-4">
      {operations.map((log) => (
        <Accordion.Item key={log.id} eventKey={log.id}>
          <Accordion.Header>
            <StatusIndicator className="me-2" status={log.status} />
            <OperationIndicator log={log} />
            <span className="mx-1">by</span>
            <b>{log.email}</b>
            <span className="mx-1">on</span>
            <b>{log.createdAt}</b>
          </Accordion.Header>
          <Accordion.Body>
            <UnitsAccordion units={log.actions} operation={log.operation} />
            {log.screenshots.length > 0 &&
              log.screenshots.map((screenshotId) => (
                <Button
                  key={screenshotId}
                  className="mt-2 me-2"
                  onClick={() => {
                    openScreenshot(screenshotId);
                    setModalShow(true);
                  }}
                >
                  Open screenshot
                </Button>
              ))}
          </Accordion.Body>
        </Accordion.Item>
      ))}
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        centered
        fullscreen
      >
        <Modal.Header closeButton>
          <Modal.Title>Screenshot</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FethcableScreenshot screenshotId={screenshotOpen} />
        </Modal.Body>
      </Modal>
    </Accordion>
  );
}

function FethcableScreenshot({ screenshotId }) {
  const [useScreenshot, setUseScreenshot] = useState("");
  axios(`api/admob-logs/screenshots/${screenshotId}`).then((response) =>
    setUseScreenshot(response.data.screenshotPath)
  );
  return <img alt="screenshot" src={useScreenshot} />;
}

export default AdmobLogsPage;
