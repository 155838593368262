import BsForm from "react-bootstrap/Form";

import Check from "./check";
import CheckList from "./check-list";
import Control from "./control";
import { NonFieldErrors } from "./error";
import Form from "./form";
import Select from "./select";

Form.NonFieldErrors = NonFieldErrors;
Form.Check = Check;
Form.CheckList = CheckList;
Form.Control = Control;
Form.Select = Select;
Form.Floating = BsForm.Floating;
Form.Group = BsForm.Group;
Form.Label = BsForm.Label;
Form.Text = BsForm.Text;

export default Form;
