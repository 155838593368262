import React from "react";
import { createRoot } from "react-dom/client";

import App from "~/base/app";
import reportWebVitals from "~/base/reportWebVitals";
import "./index.scss";
import "antd/dist/antd.css";

const rootElement = document.getElementById("react-root");
if (rootElement) {
  const root = createRoot(rootElement);
  root.render(<App />);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
