import { Col, Row } from "react-bootstrap";
import * as Yup from "yup";

import Form from "~/components/form";
import SubmitButton from "~/components/form/submit";
import { axios } from "~/utils";

/* eslint-disable no-template-curly-in-string */
const schema = Yup.object().shape({
  runFrequencyDays: Yup.number()
    .integer("Must be an integer!")
    .required("Required!")
    .min(1, "Should be >= ${min}"),
  runPeriodDays: Yup.number()
    .integer("Must be an integer!")
    .required("Required!")
    .min(1, "Should be >= ${min}"),
  daysUnchange: Yup.number()
    .integer("Must be an integer!")
    .required("Required!")
    .min(1, "Should be >= ${min}"),
});
/* eslint-enable no-template-curly-in-string */

function AdUnitInfoSection({ data, reFetch, settingsId }) {
  return (
    <div className="p-3 pb-1">
      <Form
        initialValues={data}
        validationSchema={schema}
        onSubmit={(values) =>
          axios({
            method: "PUT",
            url: `api/unit-settings/${settingsId}`,
            data: values,
          }).then(() => {
            reFetch();
          })
        }
      >
        <Row className="mb-3">
          <ReadOnlyValue name="name" label="Name" />
          <Value
            name="runFrequencyDays"
            label="Run Frequency days"
            type="number"
          />
        </Row>

        <Row className="mb-3">
          <ReadOnlyValue name="adUnit" label="Ad unit" />
          <Value name="runPeriodDays" label="Use last x days" type="number" />
        </Row>

        <Row className="mb-3">
          <ReadOnlyValue name="platform" label="Platform" />
          <Value name="daysUnchange" label="Days unchanged" type="number" />
        </Row>

        <Row>
          <Col className="d-flex justify-content-end">
            <SubmitButton />
          </Col>
        </Row>
      </Form>
    </div>
  );
}

function ReadOnlyValue({ name, label }) {
  return (
    <Col xs={4}>
      <Row>
        <Col md={3} style={{ textAlign: "end", alignSelf: "center" }}>
          <Form.Label direction="vertical">{label}</Form.Label>
        </Col>
        <Col>
          <Form.Control type="text" name={name} disabled />
        </Col>
      </Row>
    </Col>
  );
}

function Value({ name, label, ...rest }) {
  return (
    <Col xs={3}>
      <Row>
        <Col md={9} style={{ textAlign: "end", alignSelf: "center" }}>
          <Form.Label direction="vertical" style={{ textAlign: "right" }}>
            {label}
          </Form.Label>
        </Col>
        <Col md={3}>
          <Form.Control name={name} {...rest} />
        </Col>
        <Col md={12} style={{ textAlign: "end", alignSelf: "center" }}>
          <Form.Control.ErrorFeedback name={name} />
        </Col>
      </Row>
    </Col>
  );
}

export default AdUnitInfoSection;
