import React from "react";
import { Accordion, Card } from "react-bootstrap";

import PlacementsAccordion from "./placements-accordion";
import getStatusClass from "./status";

function NetworksAccordion({ networks }) {
  return (
    <Accordion>
      {networks.map((n) => (
        <Accordion.Item key={n.name} eventKey={n.name}>
          <Accordion.Header className={getStatusClass(n.status)}>
            {n.name}
          </Accordion.Header>
          <Accordion.Collapse mountOnEnter eventKey={n.name}>
            <Card.Body className="p-2">
              <PlacementsAccordion placements={n.placements} />
            </Card.Body>
          </Accordion.Collapse>
        </Accordion.Item>
      ))}
    </Accordion>
  );
}

export default NetworksAccordion;
