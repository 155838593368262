import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Modal, Col, Row, Button } from "react-bootstrap";
import * as Yup from "yup";

import Form from "~/components/form";
import SubmitButton from "~/components/form/submit";
import { axios } from "~/utils";

import CountryTierSelect from "../country-tier/select";
import EditSchema from "./validation-schema";

const schema = Yup.object()
  .shape({
    countryTier: Yup.array()
      .min(1, "Field must have at least 1 items")
      .of(Yup.mixed())
      .when("existingSettings", (existingSettings, s) => {
        const existingTiers = {};
        const existingCountries = {};
        existingSettings
          .filter((v) => v.countryTier)
          .forEach((v) => {
            existingTiers[v.countryTier.id] = true;
            v.countryTier.countries.forEach((c) => {
              existingCountries[c] = v.countryTier.name || c;
            });
          });

        return s.test(
          "disallow-existing-settings",
          "This setting already exists",
          (value, ctx) => {
            const chosenAll = value.some((v) => v === "all");
            if (chosenAll) {
              return ctx.createError({
                message: 'Setting for "all" already exists',
              });
            }
            const chosenTier = value.find((v) => typeof v === "number");
            if (chosenTier && existingTiers[chosenTier]) {
              return ctx.createError({
                message: "Setting for this tier already exists",
              });
            }
            return (
              value
                .filter((v) => typeof v === "string")
                .map((c) => {
                  if (!existingCountries[c]) return null;
                  if (existingCountries[c] === c) {
                    return ctx.createError({
                      message: `Setting for '${c}' already exists`,
                    });
                  }
                  const tier = existingCountries[c];
                  return ctx.createError({
                    message: `Setting for '${c}' already exists (in '${tier}' tier)`,
                  });
                })
                .find((v) => v) || true
            );
          }
        );
      }),
    country: Yup.string(),
  })
  .concat(EditSchema);

function CountryCreateModal({ existingSettings, unitId, onCreate }) {
  const [show, setShow] = React.useState(false);

  const closeModal = () => setShow(false);
  const showModal = () => setShow(true);
  return (
    <>
      <Button variant="success" onClick={showModal}>
        <span className="me-2">
          <FontAwesomeIcon icon={faCirclePlus} />
        </span>
        Add
      </Button>
      <Modal show={show} onHide={closeModal} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Create country setting</Modal.Title>
        </Modal.Header>
        <Form
          form={false}
          validationSchema={schema}
          initialValues={{
            countryTier: [],
            enabled: true,
            launchTarget: 0.5,
            pauseTarget: 0.2,
            pauseTargetLowQuarter: 0.1,
            existingSettings,
          }}
          onSubmit={({ existingSettings: noShadow, ...data }) =>
            axios({
              method: "POST",
              url: `api/ad-units/${unitId}/country-params`,
              data,
            }).then(() => {
              onCreate();
              closeModal();
            })
          }
        >
          <Modal.Body className="px-5">
            <Form.InnerForm>
              <Form.NonFieldErrors />

              <InputRow label="Country" name="countryTier">
                <CountryTierSelect name="countryTier" />
              </InputRow>

              <InputRow label="Enabled" name="enabled">
                <Form.Check name="enabled" />
              </InputRow>

              <InputRow label="Launch target" name="launchTarget">
                <Form.Control type="number" name="launchTarget" />
              </InputRow>

              <InputRow label="Pause target" name="pauseTarget">
                <Form.Control type="number" name="pauseTarget" />
              </InputRow>

              <InputRow
                label="Pause target low quarter"
                name="pauseTargetLowQuarter"
              >
                <Form.Control type="number" name="pauseTargetLowQuarter" />
              </InputRow>

              {/* this input is needed to allow to submit form by pressing enter */}
              <input type="submit" className="d-none" />
            </Form.InnerForm>
          </Modal.Body>
          <Modal.Footer>
            <SubmitButton title="Create" />
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

function InputRow({ label, name, children }) {
  return (
    <Form.Group as={Row} className="my-3" controlId={name}>
      <Form.Label column sm={4}>
        {label}
      </Form.Label>
      <Col sm={8} className="d-flex flex-wrap align-items-center">
        <div className="w-100">{children}</div>
        <Form.Control.ErrorFeedback name={name} />
      </Col>
    </Form.Group>
  );
}

export default CountryCreateModal;
