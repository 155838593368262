import React from "react";

import Check from "./check";

function CheckList({ name, options, type = "checkbox", ...rest }) {
  return options.map(({ value, label }) => (
    <Check
      key={value}
      name={name}
      label={label}
      value={value}
      type={type}
      {...rest}
    />
  ));
}

export default CheckList;
