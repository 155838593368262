import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { UNSAFE_DataRouterContext as DataRouterContext } from "react-router";

import { usePermissionChecker } from "~/base/user-data";
import { joinPath } from "~/utils";

import { NavigationColored, NavigationLink } from "./navigation-items";
import NavigationSection from "./navigation-items/section";

function Navbar({ minimized }) {
  const entries = useNavbar();
  return renderEntries(entries, minimized);
}

function renderEntries(entries, minimized) {
  return entries.map(([path, config, children]) => {
    const { title, icon, color, section = false } = config;
    let rv = section ? (
      <NavigationSection
        key={path}
        icon={icon && <FontAwesomeIcon icon={icon} />}
        minimized={minimized}
        title={title}
        prefix={path}
      >
        {renderEntries(children, minimized)}
      </NavigationSection>
    ) : (
      <NavigationLink
        key={path}
        minimized={minimized}
        to={path}
        title={title}
        icon={icon && <FontAwesomeIcon icon={icon} />}
      />
    );
    if (color) {
      rv = (
        <NavigationColored key={path} color={color}>
          {rv}
        </NavigationColored>
      );
    }
    return rv;
  });
}

function useNavbar() {
  const config = React.useContext(DataRouterContext).router.routes;
  const hasAllPermissions = usePermissionChecker();
  const hasAnyPermission = usePermissionChecker(false);
  return React.useMemo(
    () => extractEntries("", config, hasAllPermissions, hasAnyPermission),
    [config, hasAllPermissions, hasAnyPermission]
  );
}

function checkPermissions(routeData, hasAllPermissions, hasAnyPermission) {
  const { requiredPermissions, allowedPermissions } = routeData;
  if (requiredPermissions && !hasAllPermissions(requiredPermissions))
    return false;
  if (allowedPermissions && !hasAnyPermission(allowedPermissions)) return false;
  return true;
}

function extractEntries(
  currentPath,
  children,
  hasAllPermissions,
  hasAnyPermission
) {
  if (!children) return [];
  let rv = [];
  for (let i = 0; i < children.length; ++i) {
    const entry = children[i];
    if (
      entry.handle &&
      !checkPermissions(entry.handle, hasAllPermissions, hasAnyPermission)
    )
      continue;
    const entryPath = joinPath(currentPath, entry.path);
    if (entry.handle && entry.handle.navbar) {
      if (entry.handle.navbar.section) {
        rv.push([
          entryPath,
          entry.handle.navbar,
          extractEntries(
            entryPath,
            entry.children,
            hasAllPermissions,
            hasAnyPermission
          ),
        ]);
      } else {
        rv.push([entryPath, entry.handle.navbar]);
        rv = rv.concat(
          extractEntries(
            entryPath,
            entry.children,
            hasAllPermissions,
            hasAnyPermission
          )
        );
      }
    } else {
      rv = rv.concat(
        extractEntries(
          entryPath,
          entry.children,
          hasAllPermissions,
          hasAnyPermission
        )
      );
    }
  }
  return rv;
}

export default Navbar;
