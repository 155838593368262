import { Dropdown, DropdownButton } from "react-bootstrap";
import { Link } from "react-router-dom";

function VersionSelector({ selected, side, versions, left, right }) {
  const title = selected ? (
    <>
      v{selected.version.number}
      {selected.isExperiment && "T"} ({selected.version.createdAt})
    </>
  ) : (
    "---"
  );
  return (
    <DropdownButton variant="secondary" title={title}>
      {versions.map((v) => (
        <Entry
          key={v.number}
          side={side}
          target={v}
          left={left}
          right={right}
        />
      ))}
    </DropdownButton>
  );
}

function Entry({ side, target, left, right }) {
  if (side === "left") {
    return (
      <>
        <EntryLink
          target={target}
          current={left}
          other={right}
          currentOption="left"
          otherOption="right"
          toExperiment={false}
        />
        <EntryLink
          target={target}
          current={left}
          other={right}
          currentOption="left"
          otherOption="right"
          toExperiment
        />
      </>
    );
  }
  return (
    <>
      <EntryLink
        target={target}
        current={right}
        other={left}
        currentOption="right"
        otherOption="left"
        toExperiment={false}
      />
      <EntryLink
        target={target}
        current={right}
        other={left}
        currentOption="right"
        otherOption="left"
        toExperiment
      />
    </>
  );
}

function EntryLink({
  target,
  current,
  other,
  currentOption,
  otherOption,
  toExperiment,
}) {
  if (toExperiment && !target.hasExperiment) {
    return null;
  }
  const isActive =
    current &&
    target.number === current.version.number &&
    toExperiment === current.isExperiment;
  let url = `?${renderOption(currentOption, target.number, toExperiment)}`;
  if (other) {
    url = `${url}&${renderOption(
      otherOption,
      other.version.number,
      other.isExperiment
    )}`;
  }
  return (
    <Dropdown.Item as={Link} active={isActive} to={url}>
      v{target.number}
      {toExperiment && "T"} ({target.createdAt})
    </Dropdown.Item>
  );
}

function renderOption(option, versionNumber, isExperiment) {
  return `${option}=${versionNumber}${isExperiment ? "T" : ""}`;
}

export default VersionSelector;
