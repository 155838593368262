import React from "react";

import { toOptions } from "~/utils";

function useOptions() {
  return React.useContext(optionsContext);
}

function OptionsProvider({ value, children }) {
  return (
    <optionsContext.Provider value={value}>{children}</optionsContext.Provider>
  );
}

function transformOptions({
  networks = [],
  countries = [],
  countryTiers = [],
}) {
  const allOption = { value: "all", label: "all" };
  const countriesOptions = toOptions(countries.map((v) => v.label));
  const options =
    countryTiers.length > 0
      ? [
          allOption,
          { label: "Tiers", options: countryTiers },
          { label: "Countries", options: countriesOptions },
        ]
      : [allOption, ...countriesOptions];
  return {
    networks: [allOption, ...toOptions(networks.map((v) => v.label))],
    countries: options,
    rawNetworks: networks,
    rawCountries: countries,
  };
}

const optionsContext = React.createContext();

export { useOptions, OptionsProvider, transformOptions };
