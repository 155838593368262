import React from "react";
import { Outlet, useLoaderData } from "react-router-dom";

import { AuthStateContext, redirectToSSO } from "~/base/auth";
import { useIsRouteAllowed } from "~/base/routing";
import { SearchParamsProvider } from "~/base/search-params";
import TitleChanger from "~/base/title";
import { UserDataProvider } from "~/base/user-data";
import PageHeader from "~/components/page-header";
import { cachedAxios } from "~/utils";

import Sidebar from "./sidebar";

// TODO: move SearchParamsProvider, and useIsRouteAllowed to Root element from layout
function Layout() {
  const { loggedIn } = React.useContext(AuthStateContext);
  const userData = useLoaderData();
  if (loggedIn) {
    return (
      <SearchParamsProvider>
        <UserDataProvider value={userData}>
          <div className="d-flex">
            <TitleChanger />
            <Sidebar />
            <main className="container-fluid pb-3">
              <ProtectedOutlet />
            </main>
          </div>
        </UserDataProvider>
      </SearchParamsProvider>
    );
  }
  return redirectToSSO();
}

Layout.loader = async function loader() {
  const response = await cachedAxios("api/user-info");
  return response.data;
};

function ProtectedOutlet() {
  const isRouteAllowed = useIsRouteAllowed();
  return isRouteAllowed ? <Outlet /> : <PageHeader title="Page not found" />;
}

export default Layout;
