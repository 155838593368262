import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button } from "react-bootstrap";

import { axios, useAlert, useConfirmation } from "~/utils";

function RunButton({ unitId }) {
  const [loading, setLoading] = React.useState(false);
  const addAlert = useAlert();
  const confirmRun = useConfirmation();

  const onConfirm = () => {
    setLoading(true);
    if (!loading) {
      axios({
        url: `api/ad-units/${unitId}/run`,
        method: "POST",
      })
        .then(({ data }) => {
          data.messages.forEach(([variant, message]) => {
            addAlert(message, variant);
          });
        })
        .catch(() => {
          addAlert("Error while triggering ad unit run", "error");
        })
        .finally(() => setLoading(false));
    }
  };

  const onClick = () =>
    confirmRun(
      onConfirm,
      <p>
        Are you sure want to run the ad unit waterfall? The waterfall will be
        run forcefully and the last run check will be bypassed.
      </p>
    );

  const icon = loading ? (
    <div className="spinner-border spinner-border-sm" />
  ) : (
    <FontAwesomeIcon icon={faPlay} />
  );

  return (
    <Button variant="success" className="mx-2" onClick={onClick}>
      <span className="me-2">{icon}</span> Run
    </Button>
  );
}

export default RunButton;
