import { Formik, useFormikContext } from "formik";
import PropTypes from "prop-types";
import React from "react";
import BsForm from "react-bootstrap/Form";

import { useAlert } from "~/base/alerts";

function Form({ children, syncSubmit, onSubmit, form, ...rest }) {
  const addAlert = useAlert();

  const newOnSubmit = (values, formikBag) => {
    const validationHandler = (error) => {
      if (error.response && error.response.status === 400) {
        const { data } = error.response;
        formikBag.setStatus(
          Array.isArray(data) ? { nonFieldErrors: data } : data
        );
      } else {
        /* eslint-disable no-console */
        console.error(error);
        /* eslint-enable no-console */
        addAlert("Unhandled error while submitting the form", "error");
      }
    };
    formikBag.setStatus(null);
    const rv = onSubmit(values, formikBag);
    if (syncSubmit) {
      formikBag.setSubmitting(false);
    } else if (rv && rv.then && typeof rv.then === "function") {
      rv.catch(validationHandler).finally(() => formikBag.setSubmitting(false));
    }
  };
  const newProps = { ...rest, onSubmit: newOnSubmit };
  return (
    <Formik {...newProps}>
      {(formikProps) => renderChildren(formikProps, children, form)}
    </Formik>
  );
}

Form.propTypes = {
  form: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
  syncSubmit: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
};

Form.defaultProps = {
  form: {},
  syncSubmit: false,
};

function renderChildren(formikProps, children, form) {
  if (form) {
    const formProps = typeof form === "boolean" ? {} : form;
    return (
      <BsForm
        onReset={formikProps.handleReset}
        onSubmit={formikProps.handleSubmit}
        {...formProps}
      >
        {typeof children === "function" ? children(formikProps) : children}
      </BsForm>
    );
  }
  return typeof children === "function" ? children(formikProps) : children;
}

const InnerForm = React.forwardRef(({ children, ...rest }, ref) => {
  const { handleReset, handleSubmit } = useFormikContext();
  return (
    <BsForm ref={ref} onReset={handleReset} onSubmit={handleSubmit} {...rest}>
      {children}
    </BsForm>
  );
});

Form.InnerForm = InnerForm;

export default Form;
