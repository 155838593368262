import { useFormikContext, useField } from "formik";
import PropTypes from "prop-types";
import BsForm from "react-bootstrap/Form";

function useStatusValid(name) {
  const { status } = useFormikContext();
  if (!status) return true;
  return !(name in status);
}

function NonFieldErrors() {
  const { status } = useFormikContext();
  if (!status) return false;
  const messages = status.nonFieldErrors || [];
  if (messages.length === 0) return false;
  return (
    <BsForm.Group controlId="nonFieldErrors">
      <BsForm.Control
        type="hidden"
        name="nonFieldErrors"
        className="is-invalid"
      />
      <BsForm.Control.Feedback type="invalid" style={{ display: "block" }}>
        {renderMessages(messages)}
      </BsForm.Control.Feedback>
    </BsForm.Group>
  );
}

function ErrorFeedback(props) {
  const { children, name, ...rest } = props;
  const [field, meta] = useField({ name });
  const { status } = useFormikContext();
  let messages = [];
  if (status && field.name in status) {
    const value = status[field.name];
    if (typeof value === "string") {
      messages.push(value);
    } else {
      messages = messages.concat(value);
    }
  }
  if (meta.touched && meta.error) {
    messages.push(meta.error);
  }
  if (messages.length === 0) return false;
  if (typeof children === "function") {
    return children(renderMessages(messages));
  }
  return (
    <BsForm.Control.Feedback
      type="invalid"
      style={{ display: "block" }}
      {...rest}
    >
      {renderMessages(messages)}
    </BsForm.Control.Feedback>
  );
}

ErrorFeedback.propTypes = {
  children: PropTypes.func,
  name: PropTypes.string.isRequired,
};

ErrorFeedback.defaultProps = {
  children: null,
};

function renderMessages(messages) {
  if (messages.length === 1) return messages[0];
  return (
    <ul>
      {messages.map((m) => (
        <li key={m}>{m}</li>
      ))}
    </ul>
  );
}

function isValidationError(error) {
  return error.response && error.response.status === 400;
}

export { NonFieldErrors, ErrorFeedback, useStatusValid, isValidationError };
