import React from "react";
import { Outlet, useLoaderData } from "react-router-dom";

import { filtersLoader } from "~/blocks/ad-units/filters";
import { AdmobCredsContainer } from "~/blocks/admob/credentials";
import PageHeader from "~/components/page-header";
import { axios } from "~/utils";

import AdMobBulkCreate from "./create-page";
import AdmobBulkSelectorPage from "./selector-page";
import AdMobBulkSwap from "./swap-page";

function AdMobRoot() {
  return (
    <AdmobCredsContainer>
      <Outlet />
    </AdmobCredsContainer>
  );
}

function AdMobBulkRoot() {
  const options = useLoaderData();
  return (
    <>
      <PageHeader />
      <Outlet context={options} />
    </>
  );
}

AdMobBulkRoot.loader = async function loader() {
  const [filtersOptions, unitsData] = await Promise.all([
    filtersLoader(),
    axios("api/ad-units?page=all&active=1").then((r) => r.data),
  ]);
  return { filtersOptions, ...preprocessData(unitsData) };
};

function preprocessData(data) {
  const options = data.results;
  const nameByMaxId = Object.fromEntries(
    data.results.map((e) => [e.maxId, e.name])
  );
  const idByMaxId = Object.fromEntries(
    data.results.map((e) => [e.maxId, e.id])
  );
  const unitByMaxId = Object.fromEntries(data.results.map((e) => [e.maxId, e]));
  return { options, nameByMaxId, idByMaxId, unitByMaxId };
}

export {
  AdMobRoot,
  AdMobBulkRoot,
  AdmobBulkSelectorPage,
  AdMobBulkSwap,
  AdMobBulkCreate,
};
