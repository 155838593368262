import { useField } from "formik";
import React from "react";
import DatePicker from "react-datepicker";

import { useStatusValid } from "./error";

function DatePickerForm({ placeholderText = null, ...props }) {
  const [{ value, onBlur }, meta, { setValue }] = useField(props.name);
  const isStatusValid = useStatusValid(props.name);
  const isInvalid = meta.touched && (!isStatusValid || meta.error);
  return (
    <DatePicker
      dateFormat="yyyy-MM-dd"
      className="form-control"
      placeholderText={placeholderText || "Select a date and time"}
      selected={value ? new Date(value) : null}
      onBlur={onBlur}
      {...props}
      onChange={setValue}
      isInvalid={isInvalid}
    />
  );
}

function dateToString(date) {
  if (!date) {
    return date;
  }
  const epoch = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
  const utcDate = new Date(epoch);
  return utcDate.toISOString().substring(0, 10);
}

export { dateToString };
export default DatePickerForm;
