import { Badge } from "react-bootstrap";

function CountryTierView({ value }) {
  if (value === null) return "all";
  if (value.name === null)
    return (
      <>
        {value.countries.map((code) => (
          <Badge className="mx-1" bg="secondary" key={code}>
            {code}
          </Badge>
        ))}
      </>
    );
  const title = value.countries.join(", ");
  return (
    <Badge className="mx-1" title={title} bg="primary">
      {value.name}
    </Badge>
  );
}

export default CountryTierView;
