import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormikContext } from "formik";
import React from "react";
import { Row, Col, Button } from "react-bootstrap";

import Form from "~/components/form";
import DatePicker, { dateToString } from "~/components/form/date-picker";
import Label from "~/components/form/label";
import SubmitButton from "~/components/form/submit";
import { useFilters } from "~/components/page-header";
import { cachedAxios, ensureList, toOptions, useSearchParams } from "~/utils";

const statusOptions = toOptions(["Success", "Failed", "Partial success"]);

async function filtersLoader() {
  const response = await cachedAxios("api/users-filters");
  return response.data;
}

function TotalResultsIndicator({ total }) {
  const { activeFiltersCount } = useFilters([
    "dateAfter",
    "dateBefore",
    "actor",
    "status",
  ]);
  if (activeFiltersCount === 0) return null;
  return <h6 className="m-2">{total} item(s) found</h6>;
}

function PageFiltersForm({ options }) {
  const { state, setState } = useSearchParams();
  useFilters(["dateAfter", "dateBefore", "actor", "status"]);

  const initialValues = {
    dateAfter: state.dateAfter ? new Date(state.dateAfter) : null,
    dateBefore: state.dateBefore ? new Date(state.dateBefore) : null,
    actor: ensureList(state.actor) || [],
    status: ensureList(state.status) || [],
  };

  const onChange = (values) => {
    setState({
      dateAfter: dateToString(values.dateAfter),
      dateBefore: dateToString(values.dateBefore),
      actor: values.actor,
      status: values.status,
      page: 1,
    });
  };
  return (
    <FiltersForm
      options={options}
      initialValues={initialValues}
      onChange={onChange}
    />
  );
}

function FiltersForm({ options, initialValues, onChange }) {
  return (
    <Form initialValues={initialValues} syncSubmit onSubmit={onChange}>
      <Row className="mb-3">
        <FilterCol label="Operation date range">
          <DateRange name="date" />
        </FilterCol>
        <FilterCol label="User">
          <Form.Select name="actor" options={options.users} isMulti />
        </FilterCol>
        <FilterCol label="Status">
          <Form.Select name="status" options={statusOptions} isMulti />
        </FilterCol>
      </Row>
      <Row>
        <Col className="d-flex flex-row justify-content-end" md={12} sm={10}>
          <Col xs="auto" className="me-2">
            <ResetButton />
          </Col>
          <Col xs="auto">
            <SubmitButton icon={false} title="Apply" />
          </Col>
        </Col>
      </Row>
    </Form>
  );
}

function DateRange({ name }) {
  const { values } = useFormikContext();
  const after = `${name}After`;
  const before = `${name}Before`;
  const startDate = values[after];
  const endDate = values[before];
  return (
    <Row>
      <Col xs={6}>
        <DatePicker
          name={after}
          placeholderText="From:"
          autoComplete="off"
          selectsStart
          startDate={startDate}
          endDate={endDate}
          maxDate={endDate}
        />
      </Col>
      <Col xs={6}>
        <DatePicker
          name={before}
          placeholderText="To:"
          autoComplete="off"
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
        />
      </Col>
    </Row>
  );
}

function FilterCol({ children, label }) {
  return (
    <Col md={4} xs={5}>
      <Label direction="vertical" text={label}>
        {children}
      </Label>
    </Col>
  );
}

function ResetButton() {
  const { resetForm, handleSubmit } = useFormikContext();
  const clearFilters = () => {
    resetForm();
    handleSubmit();
  };
  return (
    <Button variant="outline-danger" onClick={clearFilters}>
      <span className="me-2">
        <FontAwesomeIcon icon={faCircleXmark} />
      </span>
      Reset Filters
    </Button>
  );
}

export { filtersLoader, PageFiltersForm, FiltersForm, TotalResultsIndicator };
