import React from "react";
import { Row, Col, Form, ListGroup, InputGroup } from "react-bootstrap";
import { useLoaderData } from "react-router-dom";

import { DetachedSelect as Select } from "~/components/form/select";
import PageHeader from "~/components/page-header";
import { toOptions, axios } from "~/utils";

function CountryWaterfallsPage() {
  const data = useLoaderData();
  if (!data.version) {
    return (
      <>
        <PageHeader />
        <div className="container">
          <p>No version exist for the Ad Unit</p>
        </div>
      </>
    );
  }
  return <WaterfallViewer data={data} />;
}

CountryWaterfallsPage.loader = async function loader({ params }) {
  const response = await axios(
    `api/ad-units/${params.adUnitID}/country-waterfall`
  );
  return response.data;
};

function WaterfallViewer({ data }) {
  const [isTest, setIsTest] = React.useState(false);
  const countryOptions = React.useMemo(
    () => toOptions(Object.keys(isTest ? data.testWaterfall : data.waterfall)),
    [data, isTest]
  );
  const [country, setCountry] = React.useState(
    countryOptions.length ? countryOptions[0].value : null
  );
  const placements = React.useMemo(
    () => (isTest ? data.testWaterfall : data.waterfall)[country] || [],
    [data, isTest, country]
  );
  return (
    <>
      <PageHeader />
      <form className="form mt-3">
        <div className="container">
          <InputRow label="Name" name="name">
            <Form.Control value={data.name} disabled />
          </InputRow>

          <InputRow label="Max Id" name="maxId">
            <Form.Control value={data.maxId} disabled />
          </InputRow>

          <InputRow label="Use test variant" name="isExperimentData">
            <Form.Check
              type="switch"
              value={isTest}
              onChange={(e) => setIsTest(e.target.checked)}
            />
          </InputRow>

          <InputRow label="Country" name="country">
            <Select
              value={country}
              onChange={setCountry}
              options={countryOptions}
            />
          </InputRow>
        </div>
      </form>
      <div className="container">
        <Row>
          <Col xs={12}>
            <p>Using the latest existing Ad Unit version: {data.version}</p>
          </Col>
          <Col className="col-md-12">
            <PlacementsTable placements={placements} country={country} />
          </Col>
        </Row>
      </div>
    </>
  );
}

function PlacementsTable({ placements, country }) {
  const [networkSort, setNetworkSort] = React.useState("none");
  const cycleNetworkSort = () => {
    setNetworkSort(sortTransition[networkSort]);
  };
  const sortedPlacements = React.useMemo(
    () =>
      placements.sort((a, b) => {
        if (networkSort !== "none") {
          let result = a.network.localeCompare(b.network);
          result = networkSort === "desc" ? -result : result;
          if (result !== 0) return result;
        }
        return b.cpm - a.cpm;
      }),
    [placements, networkSort]
  );
  if (placements.length === 0) {
    return <p>There are no placements.</p>;
  }
  return (
    <>
      <h4 className="mt-5 mb-4">{country} Waterfall</h4>
      <Row className="mx-0 mb-2">
        <Col sm={3} onClick={cycleNetworkSort}>
          Network Name <SortIndicator value={networkSort} />
        </Col>
        <Col sm={7}>Placement ID</Col>
        <Col sm={2}>
          CPM Price <SortIndicator value="desc" />
        </Col>
      </Row>
      <ListGroup>
        {sortedPlacements.map((p) => (
          <ListGroup.Item className="p-0" key={p.id}>
            <Row className="mx-0 py-3">
              <Col sm={3}>
                <Form.Control type="text" value={p.network} disabled />
              </Col>
              <Col sm={7}>
                <Form.Control type="text" value={p.id} disabled />
              </Col>
              <Col sm={2}>
                <InputGroup>
                  <InputGroup.Text>$</InputGroup.Text>
                  <Form.Control type="text" value={p.cpm.toFixed(2)} disabled />
                </InputGroup>
              </Col>
            </Row>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </>
  );
}

const sortTransition = { none: "asc", asc: "desc", desc: "none" };

function SortIndicator({ value }) {
  if (value === "none") {
    return null;
  }
  if (value === "asc") {
    return <code>&#x25B2;</code>;
  }
  if (value === "desc") {
    return <code>&#x25BC;</code>;
  }
  return null;
}

function InputRow({ label, name, children }) {
  return (
    <Form.Group as={Row} controlId={name} className="py-2">
      <Form.Label
        column
        sm={2}
        style={{ textAlign: "right", alignSelf: "center" }}
      >
        {label}
      </Form.Label>
      <Col sm={4} className="d-flex flex-wrap align-items-center">
        <div className="w-100">{children}</div>
      </Col>
    </Form.Group>
  );
}

export default CountryWaterfallsPage;
