import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ActiveIndicator({ active }) {
  return (
    <>
      <FontAwesomeIcon
        className="me-2"
        color={active ? "green" : "red"}
        icon={faCircle}
      />
      {active ? "active" : "inactive"}
    </>
  );
}

export default ActiveIndicator;
