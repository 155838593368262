import React from "react";
import { Table, Row, Col } from "react-bootstrap";

import Boolean from "~/components/boolean";

import CountryTierView from "../country-tier/view";
import NetworkCreateModal from "./create-modal";
import NetworkDeleteButton from "./delete-button";
import NetworkEditModal from "./edit-modal";

function NetworkSettingsTable({ data, reFetch, unitId }) {
  return (
    <>
      <Row className="px-3 my-3">
        <Col className="me-auto">
          <h5 className="">Network settings</h5>
        </Col>
        <Col xs="auto">
          <NetworkCreateModal
            existingSettings={data}
            unitId={unitId}
            onCreate={reFetch}
          />
        </Col>
      </Row>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th scope="col">Countries</th>
            <th scope="col">Network</th>
            <th scope="col">Enabled</th>
            <th scope="col">Active Minimum</th>
            <th scope="col">Active Maximum</th>
            <th scope="col">Lowest Placement Active</th>
            <th scope="col">Launch All Placements</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((entry) => (
            <tr key={entry.id}>
              <th scope="row">
                <CountryTierView value={entry.countryTier} />
              </th>
              <th scope="row">{entry.network || "all"}</th>
              <td>
                <Boolean value={entry.enabled} />
              </td>
              <td>{entry.activeMinimum}</td>
              <td>{entry.activeMaximum}</td>
              <td>
                <Boolean value={entry.lowestPlacementActive} />
              </td>
              <td>
                <Boolean value={entry.launchAllPlacements} />
              </td>
              <td>
                <NetworkEditModal
                  data={entry}
                  unitId={unitId}
                  onEdit={reFetch}
                />
                <NetworkDeleteButton
                  data={entry}
                  unitId={unitId}
                  onDelete={reFetch}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}

export default NetworkSettingsTable;
