import { faClockRotateLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Table, Card, Button } from "react-bootstrap";
import {
  useParams,
  Link,
  defer,
  useLoaderData,
  useRevalidator,
} from "react-router-dom";

import IconButton from "~/components/icon-button";
import PageHeader from "~/components/page-header";
import { axios, cachedAxios } from "~/utils";

import AdUnitEditSectionForm from "./ad-unit-edit";
import CopyCountryWaterfallButton from "./copy-country-waterfall-button";
import RunButton from "./run-button";

function AdUnitConfigPage() {
  const { adUnit, optionsData, filtersPromise } = useLoaderData();
  const { adUnitID } = useParams();
  const revalidator = useRevalidator();

  return (
    <>
      <PageHeader />
      <div>
        <Card className="mb-4">
          <Card.Body>
            <AdUnitEditSectionForm
              adUnit={adUnit}
              optionsData={optionsData}
              onEdit={revalidator.revalidate}
            />
          </Card.Body>
        </Card>
      </div>
      <div>
        <Card className="mb-4">
          <Card.Body>
            <Card.Title>Actions</Card.Title>
            <RunButton unitId={adUnitID} />
            <CopyCountryWaterfallButton
              unitId={adUnitID}
              filtersPromise={filtersPromise}
            />
            <Link to="waterfalls">
              <Button variant="secondary" className="mx-2">
                View Country&apos;s waterfall
              </Button>
            </Link>
            <Link to="versions">
              <Button variant="secondary" className="mx-2">
                <span className="me-2">
                  <FontAwesomeIcon icon={faClockRotateLeft} />
                </span>{" "}
                View versions
              </Button>
            </Link>
          </Card.Body>
        </Card>
      </div>
      <AdUnitSettingsTable settings={adUnit.settings} />
    </>
  );
}

AdUnitConfigPage.loader = async function loader({ params }) {
  const filtersPromise = cachedAxios("api/settings-filters").then(
    (r) => r.data
  );
  const [adUnitResponse, optionsResponse] = await Promise.all([
    axios(`api/ad-units/${params.adUnitID}`),
    cachedAxios("api/ad-units-choices"),
  ]);
  return defer({
    adUnit: adUnitResponse.data,
    optionsData: optionsResponse.data,
    filtersPromise,
  });
};

function AdUnitSettingsTable({ settings }) {
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th scope="col">ID</th>
          <th scope="col">Run frequency days</th>
          <th scope="col">Days unchange</th>
          <th scope="col">Use last x days</th>
          <th scope="col">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr key={settings.id}>
          <th scope="row">{settings.id}</th>
          <td>{settings.runFrequencyDays}</td>
          <td>{settings.daysUnchange}</td>
          <td>{settings.runPeriodDays}</td>
          <td>
            <Link to={`schedules/${settings.id}/edit`}>
              <IconButton.Edit />
            </Link>
          </td>
        </tr>
      </tbody>
    </Table>
  );
}

export default AdUnitConfigPage;
